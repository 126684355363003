<template>
  <div>
    <v-toolbar fixed class="breadcrumbs">
      <swBreadCrumbs :breadcrumbs="breadcrumbs" @callback="activate" />

      <v-spacer></v-spacer>
      <div v-if="notPrefiltered">
        <v-btn class="info mr-2" @click="$refs.swSkuEditorPrefilter.addFilter()">
          <v-icon>mdi-filter-plus</v-icon>
          <div v-if="$vuetify.breakpoint.smAndUp" class="ml-2">{{ swT('add_filter') }}</div>
        </v-btn>
        <v-btn class="info" data-test="showSkus" :disabled="!selectionHasBeenMade" @click="reset()">
          <v-icon>mdi-eye</v-icon>
          <div v-if="$vuetify.breakpoint.smAndUp" class="ml-2">{{ swT('show_skus') }}</div>
        </v-btn>
      </div>
      <div v-else class="d-flex">
        <swButton v-if="showImageSync" data-test="btn-image-sync" icon="mdi-image-sync" :tooltip="swT('imageSync')" @click="openBindImageMenu" />
        <v-menu v-model="bindImageMenu.active" offset-y transition="slide-y-transition" :position-x="bindImageMenu.x" :position-y="bindImageMenu.y" absolute>
          <v-list
            v-for="strategy of ['articleCode', 'articleCode_colorCode', 'articleCode_and_colorCode', 'articleDescription_and_color', 'articleCode_and_colorDescription', 'barcode']"
            :key="strategy"
          >
            <v-list-item :data-test="`menu-bind-image-${strategy}`" @click="bindImages(strategy)">
              {{ swT(`strategy_${strategy.toLowerCase()}`) }}
            </v-list-item>
          </v-list>
        </v-menu>
        <swButton v-if="showFPSync" data-test="btn-foxpro-sync" icon="mdi-gitlab" :tooltip="swT('foxproSync')" @click="fpSync" />
        <span v-if="dataProviders.length > 1">
          <swButton v-if="showCloudSync" data-test="btn-cloud-sync" icon="mdi-cloud-sync" :tooltip="swT('cloudSync')" @click="openCloudSyncMenu" />
          <v-menu v-model="cloudSyncMenu.active" offset-y transition="slide-y-transition" :position-x="cloudSyncMenu.x" :position-y="cloudSyncMenu.y" absolute>
            <v-list v-for="dataProvider of dataProviders" :key="dataProvider.name">
              <v-list-item :data-test="`menu-cloud-sync-${dataProvider.name}`" @click="cloudSync(dataProvider.name)">
                {{ swT(`dataprovider-${dataProvider.name}`) }} {{ formatDate(dataProvider.lasttime) }}
              </v-list-item>
            </v-list>
          </v-menu>
        </span>
        <span v-else>
          <swButton v-if="showCloudSync" data-test="btn-cloud-sync" icon="mdi-cloud-sync" :tooltip="swT('cloudSync')" @click="cloudSync(dataProviders[0].name)" />
        </span>
        <v-btn data-test="addSku" dark large color="success" class="mx-1 px-1" @click="addNewSku">
          {{ swT('new') }}
        </v-btn>
        <v-btn v-if="$vuetify.breakpoint.mdAndUp" data-test="btn-import-xls" class="mx-1 px-1" :loading="$store.state.importBusy" dark large color="info" @click="importXLSX">
          <v-icon>
            mdi-microsoft-excel
          </v-icon>
          {{ swT('import') }}
        </v-btn>
        <v-btn v-if="$vuetify.breakpoint.mdAndUp" data-test="btn-export" class="mx-1 px-1" :loading="$store.state.exportBusy" dark large color="info" @click="openExportMenu">
          <v-icon>
            mdi-export
          </v-icon>
          {{ swT('export') }}
        </v-btn>
        <v-menu v-model="exportMenu.active" offset-y transition="slide-y-transition" :position-x="exportMenu.x" :position-y="exportMenu.y" absolute>
          <v-list v-for="exportOption of exportOptions" :key="exportOption.name">
            <v-list-item
              :data-test="`export--${exportOption.name}`"
              @click="
                const fun = exportOption.function
                fun()
              "
            >
              <v-list-item-icon>
                <v-icon>
                  {{ exportOption.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ swT(`${exportOption.name}`) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-if="showImportHistory && $vuetify.breakpoint.mdAndUp"
          data-test="btn-import"
          class="mx-1 px-1"
          :loading="$store.state.importBusy"
          dark
          large
          color="info"
          @click="openSkuImportHistoryDialog"
        >
          <v-icon>
            mdi-folder-open
          </v-icon>
          {{ swT('history') }}
        </v-btn>
      </div>
    </v-toolbar>

    <swSkuEditorPrefilter v-if="notPrefiltered" ref="swSkuEditorPrefilter" @sku-editor-prefiltered="prefilter($event)" />

    <v-container v-else>
      <keep-alive>
        <swSkuEditor
          :skus="skus"
          :advanced-commands="!$route.query.filter"
          :masterdata="isMasterData"
          :brand="$route.query.brand"
          :ensure-fields="fields"
          @save="save"
          @cancel="reset"
          @addSku="addSku"
          @updateSku="updateSku"
        />
      </keep-alive>

      <v-snackbar v-model="snackbar" :vertical="true" min-width="70%" timeout="-1" shaped outlined multi-line>
        <pre class="green--text font-weight-bold">{{ errorMsg }}</pre>
        <template v-slot:action="{ attrs }">
          <v-btn color="success" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import filtrexOptions from '../functions/filtrexOptions'
import globalStore from '../store/globalStore'
import productFunctions from '../functions/products'
import skuEditorFunctions from '../functions/skuEditorFunctions'
import tools from '../functions/tools'
import userFunctions from '../functions/users'

import { compileExpression } from 'filtrex'
import { deepCopy, hashBrand } from '@softwear/latestcollectioncore'
import { eventBus } from '../main'
import { format } from 'date-fns'
import { markRaw } from 'vue'
import { swT } from '@/functions/i18n'

import swBreadCrumbs from '../components/swBreadCrumbs.vue'
import swButton from '../components/swButton.vue'
import swSkuEditor from '../components/swSkuEditor.vue'
import swSkuEditorPrefilter from '../components/swSkuEditorPrefilter.vue'

export default {
  components: {
    swBreadCrumbs,
    swButton,
    swSkuEditor,
    swSkuEditorPrefilter,
  },
  props: ['filter', 'redirect'],
  data() {
    return {
      swT,
      bindImageMenu: { active: false, x: 0, y: 0 },
      cloudSyncMenu: { active: false, x: 0, y: 0 },
      exportMenu: { active: false, x: 0, y: 0 },
      skus: [],
      fields: [],
      errorMsg: '',
      snackbar: false,
      activeFilter: '',
      localProductGroupFilterEquals: true,
      filterTerms: [],
    }
  },
  computed: {
    showFPSync() {
      return userFunctions.hasRole(this.$store.getters.roles, 'impersonator') && this.skus.length > 0
    },
    selectionHasBeenMade() {
      return this.filterTerms.some((f) => f.selected.length > 0)
    },
    notPrefiltered() {
      return !this.filterExpressionFromProp && !this.$route.query.eventLog && !this.activeFilter && this.$route.path == '/skueditor'
    },
    breadcrumbs() {
      const crumb1Text = swT(this.$route.query.masterdata == 'true' ? 'masterdata' : 'skueditor')
      const brand = this.brandFromRoute()

      if (this.$route.query.from == 'skuEditor') {
        // we assume there is a brand and an articleCode can be obtained from a filtrex expression
        const articleCode = this.$route.query.filter.split('"')[1]
        return [
          { text: crumb1Text, disabled: false, to: '/skueditor' },
          { text: `${brand.name} / ${articleCode}`, disabled: false, to: `/products?brandId=${hashBrand(brand.name)}` },
        ]
      }

      if (brand) {
        return [
          { text: crumb1Text, disabled: false, to: '/skueditor' },
          { text: brand.name, disabled: true },
        ]
      }
      return [{ text: crumb1Text, disabled: false, callback: true }]
    },
    showImportHistory() {
      return this.isAdmin && !this.isMasterData
    },
    showImageSync() {
      return this.isAdmin && this.skus.length > 0 && this.$route?.query.brand
    },
    isMasterData() {
      return this.$route.query.masterdata == 'true'
    },
    isAdmin() {
      if (userFunctions.hasRole(this.$store.getters.roles, 'sw,products-dataprovider_admin')) return true
      return false
    },
    sourceCollection() {
      return !(this.$route.query.masterdata == 'true') && this.isAdmin ? 'selectedbrand' : 'sku'
    },
    myCollections() {
      return tools.getUniqueValues(globalStore.getLatestCollectionArray('sku').map((s) => s.collection)).sort()
    },
    myBrands() {
      return tools.getUniqueValues(globalStore.getLatestCollectionArray('sku').map((s) => s.brand)).sort()
    },
    myProductGroups() {
      return tools.getUniqueValues(globalStore.getLatestCollectionArray('sku').map((s) => s.articleGroup)).sort()
    },
    filterExpressionFromProp() {
      return this.filter
    },
    dataProviders() {
      const brand = this.brandFromRoute()
      if (!brand) return []
      const availableDataProvideres = brand.count > 0 ? [{ name: 'latestcollection', lasttime: brand.lasttime }] : []
      if (brand.dataProviders)
        Object.keys(brand.dataProviders).forEach((key) => {
          if (brand.dataProviders[key].count > 0 && userFunctions.hasDataProviderSubscription(key, this.$store.state))
            availableDataProvideres.push({ name: key, lasttime: brand.dataProviders[key].lasttime })
        })
      return availableDataProvideres
    },
    exportOptions() {
      const options = []
      options.push({ name: 'xls', icon: 'mdi-microsoft-excel', function: this.exportXLSX })
      options.push({ name: 'json', icon: 'mdi-code-json', function: this.exportJSON })
      return options
    },
    showCloudSync() {
      return this.dataProviders.length > 0 && !this.isAdmin && this.skus.length > 0 && this.$route.query.brand
    },
  },
  watch: {
    showImageSync() {
      return this.isAdmin && this.skus.length > 0 && this.$route?.query.brand
    },
  },
  async created() {
    await this.$store.state.skuPromise

    eventBus.$on('resetSkuEditor', this.activate)

    this.$watch('$store.state.filter', this.reset)
    this.$watch(
      'notPrefiltered',
      function() {
        this.$store.state.hideMainFilter = this.notPrefiltered
      },
      { immediate: true }
    )
    this.$store.state.mainFilterPrompt = swT('fuzzy_search')
    this.$store.state.hideMainFilter = true
    await this.reset()
  },
  destroyed() {
    eventBus.$off('resetSkuEditor')
  },
  async activated() {
    this.$store.state.hideMainFilter = true
    await this.activate()
  },
  async deactivated() {
    this.$store.state.hideMainFilter = false
  },
  methods: {
    addNewSku() {
      eventBus.$emit('openChooseNewSkuMethod')
    },
    prefilter(payload) {
      this.filterTerms = payload
    },
    fieldTranslationKey(field) {
      if (field.substring(0, 1) == '_') return field.substring(1)
      return 'sku.' + field
    },
    getItems(field) {
      return ['( Blanks )'].concat(skuEditorFunctions.getItems(field))
    },
    async activate() {
      this.$store.dispatch('clearFilter')
      this.filterTerms = []
      await this.reset()
    },
    brandFromRoute() {
      // const brandId = this.filter?.replace('hashBrand(brand)==', '').replaceAll('"', '')
      const brandId = this.$route.query.brand
      return productFunctions.getBrandSettings(brandId, this.$store.state.brands)

      // return this.$store.state.brands.find((brand) => brand.collection == brandId)
    },
    formatDate(date) {
      return format(date, 'yyyy-MM-dd')
    },
    openBindImageMenu(e) {
      this.bindImageMenu.active = false
      this.bindImageMenu.x = e.clientX
      this.bindImageMenu.y = e.clientY + 20
      this.$nextTick(() => {
        this.bindImageMenu.active = true
      })
    },
    openCloudSyncMenu(e) {
      this.cloudSyncMenu.active = false
      this.cloudSyncMenu.x = e.clientX
      this.cloudSyncMenu.y = e.clientY + 20
      this.$nextTick(() => {
        this.cloudSyncMenu.active = true
      })
    },
    openExportMenu(e) {
      this.exportMenu.active = false
      this.exportMenu.x = e.clientX
      this.exportMenu.y = e.clientY + 20
      this.$nextTick(() => {
        this.exportMenu.active = true
      })
    },
    importXLSX() {
      eventBus.$emit('importXLSX')
    },
    openSkuImportHistoryDialog() {
      eventBus.$emit('openSkuImportHistory')
    },
    cloudSync(dataProvider) {
      eventBus.$emit('cloudSync', dataProvider == 'latestcollection' ? {} : { dataProvider })
    },
    bindImages(strategy) {
      eventBus.$emit('bindImages', strategy)
    },
    fpSync() {
      eventBus.$emit('fpSync')
    },
    exportXLSX() {
      eventBus.$emit('exportXLSX')
    },
    exportJSON() {
      eventBus.$emit('exportJSON')
    },
    addSku(sku) {
      this.skus.push(sku)
    },
    updateSku(sku) {
      const index = this.skus.findIndex((s) => s.barcode == sku.barcode)
      this.skus[index] = sku
    },
    removeIrrelevantAttributes(sku) {
      delete sku.margin
      delete sku.calculation
      delete sku.__modified
      if (sku.id.substring(0, 4) != 'tmp-') return

      // Remove Tmp ids
      delete sku.id
      delete sku.barcode
    },
    async save(updatedSkus) {
      this.$store.state.loading = true
      eventBus.$emit('resetSwSkuEditor')
      const collectionToSave = globalStore.getLatestCollectionAPI(this.sourceCollection)
      const skusToUpdate = Object.keys(updatedSkus).map((id) => updatedSkus[id])
      skusToUpdate.forEach(this.removeIrrelevantAttributes)

      await this.$store.dispatch('updateObjects', {
        api: collectionToSave,
        data: skusToUpdate,
        auditHeaders: {
          'x-transaction-audit-source': 'Sku editor',
        },
      })
      this.$store.dispatch('computeUniqueSkuProperties', globalStore.getLatestCollectionArray(this.sourceCollection))
      // Reset the sku editor to show the barcode of the added sku if the barcode is auto generated
      this.reset()
      this.$store.state.loading = false
    },
    async deriveSkusFromEvent(eventLogId) {
      await this.$store.dispatch('ensureTenantCollection', 'eventlog')
      const event = (await globalStore.getLatestCollectionObject('eventlog'))[eventLogId]
      if (event.payload.command != 'SkuEditor') {
        this.$store.dispatch('raiseAlert', {
          header: 'unknownOrWrongId',
          type: 'error',
          timeout: 5000,
        })
        return
      }
      const skuIndex = event.payload.rows.reduce((agg, el) => {
        agg[el.id] = true
        return agg
      }, {})

      this.skus = deepCopy(globalStore.getLatestCollectionArray(this.sourceCollection).filter((sku) => skuIndex[sku.id]))
      this.fields = event.payload.context.selectedGroups.filter((g) => g.substring(0, 4) == 'sku.').map((g) => g.substring(4))
    },
    buildActiveFilterExpression() {
      if (this.filterExpressionFromProp) return this.filterExpressionFromProp
      if (this.$route.query.brand) {
        const brand = this.brandFromRoute() || { collection: this.$route.query.brand }
        // if (brand) return `hashBrand(brand)=="${brand.collection}"`
        let brandHashes
        if (!brand.aliases) brandHashes = [brand.collection]
        else brandHashes = brand.aliases.concat([brand.collection])
        const list = brandHashes.map((a) => `"${a}"`).join(',')
        if (brand) return `(BRANDHASH in (${list}) or hashBrand(brand) in (${list}))`
      }
      let filterExpression = ''
      this.filterTerms.forEach((f) => {
        if (f.selected.length > 0) {
          if (filterExpression != '') filterExpression = filterExpression + ' and '
          if (f.selected.includes('( Blanks )')) {
            if (f.negate) {
              filterExpression = filterExpression + `not empty( '${f.field}' )`
              return
            }
            filterExpression = filterExpression + `empty( '${f.field}' )`
            return
          }
          const operator = f.negate ? 'not in' : 'in'
          filterExpression = filterExpression + `('${f.field}' ${operator} (${f.selected.map((x) => '"' + x + '"').join(',')}))`
        }
      })
      return filterExpression
    },
    async reset() {
      if (this._inactive) return
      this.skus = []
      this.activeFilter = ''
      this.errorMsg = ''
      this.snackbar = false
      eventBus.$emit('resetSwSkuEditor')
      this._computedWatchers.dataProviders.run()
      this._computedWatchers.myCollections.run()
      this._computedWatchers.myBrands.run()
      this._computedWatchers.myProductGroups.run()

      const eventLogId = this.$route.query?.eventLog
      if (eventLogId) return await this.deriveSkusFromEvent(eventLogId)
      this.activeFilter = this.buildActiveFilterExpression()
      this.deriveSkusFromFilter()
      // When deeplinking into this route with a hashBrand filter, the computed properties cannot yet resolve. Run it again.
      this.$store.state.hideMainFilter = this.notPrefiltered
    },
    deriveSkusFromFilter() {
      try {
        const fuzzySearchString = this.$store.state.filter?.toLowerCase() || ''
        if (!this.activeFilter && !fuzzySearchString) return
        const filtrexCallbackFunction = this.activeFilter ? compileExpression(this.activeFilter, filtrexOptions.filtrexOptions) : () => true
        const fuzzySearchFunction = fuzzySearchString
          ? (sku) => Object.values(sku).find((value) => (typeof value == 'string' ? value.toLowerCase().includes(fuzzySearchString) : ('' + value).includes(fuzzySearchString)))
          : () => true
        this.skus = []
        this.$store.state.loading = true
        setTimeout(
          () => {
            this.skus = markRaw(
              deepCopy(
                globalStore
                  .getLatestCollectionArray(this.sourceCollection)
                  .filter((sku) => filtrexCallbackFunction(sku))
                  .filter((sku) => fuzzySearchFunction(sku))
              )
            )
            this.$store.state.loading = false
          },
          process.env.NODE_ENV === 'development' ? 10 : 1000
        )
      } catch (e) {
        this.errorMsg = e.message
        this.snackbar = true
      }
    },
  },
}
</script>
