import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import store from '../store/index'
import { router } from '../router'

// A map to associate each component name with its dynamic import path and the events it can emit
const dialogMap = {
  swPromptDialog: { importPath: () => import('@/components/swPromptDialog.vue'), emitEventValue: 'value' },
  swInfoDialog: { importPath: () => import('@/components/swInfoDialog.vue'), emitEventValue: null },
  swInventoryChangeDialog: { importPath: () => import('@/components/swInventoryChangeDialog.vue'), emitEventValue: 'value' },
  swPrintDialog: { importPath: () => import('@/components/swPrintDialog.vue'), emitEventValue: null },
  swValuePickDialog: { importPath: () => import('@/components/swValuePickDialog.vue'), emitEventValue: 'value' },
  swXLSImportDialog: { importPath: () => import('@/components/swXLSImportDialog.vue'), emitEventValue: 'value' },
  swBrandPopup: { importPath: () => import('@/components/swBrandPopup.vue'), emitEventValue: 'value' },
  swYesNoDialog: { importPath: () => import('@/components/swYesNoDialog.vue'), emitEventValue: 'value' },
  swYesNoCancelDialog: { importPath: () => import('@/components/swYesNoCancelDialog.vue'), emitEventValue: 'value' },
  swChooseNewSkuMethod: { importPath: () => import('@/components/swChooseNewSkuMethod.vue'), emitEventValue: 'value' },
  swLayoutObjectEditorDialog: { importPath: () => import('@/components/swLayoutObjectEditorDialog.vue'), emitEventValue: 'value' },
  swSkuImportHistory: { importPath: () => import('@/components/swSkuImportHistory.vue'), emitEventValue: 'value' },
}

// Handles the action when a dialog button is pressed
function handleButtonPress(component, resolve, value) {
  resolve(value) // Resolve the promise with the provided value

  // Remove the component from the DOM and destroy the Vue instance
  component.$destroy()
  document.body.removeChild(component.$el)
  store.state.dialogDisplayed = false // Mark that no dialog is currently displayed
}

// Creates and displays the dialog component
async function createDialogComponent(dialog, props) {
  // Set the dialogDisplayed state to true to prevent interference with keyboard shortcuts on the page
  store.state.dialogDisplayed = true

  // Dynamically import the dialog component
  const { default: DialogComponent } = await dialog.importPath()
  const DialogConstructor = Vue.extend(DialogComponent)

  const dialogInstance = new DialogConstructor({
    propsData: props,
    vuetify, // Inject the Vuetify instance here
    store, // Inject the store here
    router, // Inject the router
  })

  // Manually mount the dialog instance to an element
  dialogInstance.$mount(document.createElement('div'))

  // Append the dialog component to the DOM
  document.body.appendChild(dialogInstance.$el)

  // Return a promise to handle events emitted by the dialog component
  return new Promise((resolve) => {
    dialogInstance.$on('dialogServiceEvent', (value) => {
      handleButtonPress(dialogInstance, resolve, dialog.emitEventValue ? value : null)
    })
  })
}

// Public function to show a dialog based on the component name and provided properties
export async function showDialog(componentName: string, props: any) {
  if (!componentName) throw new Error(`Component name is not provided.`)

  const dialog = dialogMap[componentName]
  if (!dialog) throw new Error(`Component name "${componentName}" is not defined.`)

  // Set the value property to true to make the dialog visible by default
  if (!props) props = { value: true }
  else if (props.constructor === Object) props['value'] = true

  return createDialogComponent(dialog, props)
}
