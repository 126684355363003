const siteLogo = function(url: string): string {
  if (url.includes('qliqpoint.fashion')) return '/img/qplogotr.png'
  if (url.includes('fashionunited.latestcollection')) return '/img/fashionunited.png'
  if (url.includes('swretail.latestcollection')) return '/img/swretail.png'
  if (url.includes('mplus.latestcollection')) return '/img/mplus.png'
  return '/img/lclogotr.png'
}

export default {
  siteLogo,
}
