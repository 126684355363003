function setLanguage(store: any, languageCode: string): string {
  const uiSettingsExist = store.state.activeConfig?.UISettings

  if (uiSettingsExist) {
    store.dispatch('updatePreference', { key: 'UISettings.language', value: languageCode })
  } else {
    store.state.locale = languageCode
  }

  return languageCode
}

function checkLanguage(store) {
  let savedLanguage

  if (store.state.activeConfig?.UISettings) {
    if (store.state.beforeAuthLanguage) {
      savedLanguage = setLanguage(store, store.state.beforeAuthLanguage)
      store.state.beforeAuthLanguage = ''
    } else {
      savedLanguage = store.state.activeConfig.UISettings.language?.value
    }
  } else {
    savedLanguage = store.state.locale
  }

  if (savedLanguage) store.state.locale = savedLanguage

  return savedLanguage || ''
}

export default { checkLanguage, setLanguage }
