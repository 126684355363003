import { hashBrand } from '@softwear/latestcollectioncore'

const lower = (s) => s.toLowerCase()
const upper = (s) => s.toUpperCase()
const empty = (s) => s == null || s == undefined || s == ''

const filtrexOptions = {
  extraFunctions: { lower, upper, hashBrand, empty },
}

export default {
  filtrexOptions,
}
