import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import nl from 'vuetify/lib/locale/nl'
import de from 'vuetify/lib/locale/de'
import en from 'vuetify/lib/locale/en'
import tools from '../functions/tools'

Vue.use(Vuetify)

const LIGHT_THEMES = {
  latestcollection: {
    primary: '#1976D2',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
  qliqpoint: {
    primary: '#00509d',
    secondary: '#b0bec5',
    info: '#00509d',
    accent: '#8c9eff',
    error: '#b71c1c',
    success: '#008800',
    warning: '#FB8C00',
  },
}
const DARK_THEMES = {
  latestcollection: {
    primary: '#2196F3',
    secondary: '#424242',
    accent: '#FF4081',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
  qliqpoint: {
    primary: '#00509d',
    info: '#00509d',
    secondary: '#b0bec5',
    accent: '#8c9eff',
    success: '#006600',
    error: '#b71c1c',
  },
}

const theme = tools.getTheme()

export default new Vuetify({
  theme: {
    themes: {
      light: LIGHT_THEMES[theme],
      dark: DARK_THEMES[theme],
    },
  },
  lang: {
    locales: { nl, de, en },
    current: 'nl',
  },
})
