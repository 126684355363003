import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import { router } from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import swAuth from './components/swAuth.vue'
import swCurrency from './components/swCurrency.vue'
import vueApexCharts from 'vue-apexcharts'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'
import longPress from './directives/longpress'
import VueHotkey from 'v-hotkey'
import tools from './functions/tools'
import { eventBus } from './functions/eventBus'

Vue.use(VueHotkey)
Vue.directive('longpress', longPress)

Vue.config.productionTip = false
Vue.component('swAuth', swAuth)
Vue.component('swCurrency', swCurrency)
Vue.component('apexchart', vueApexCharts)
Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
  locale: 'nl-NL',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
})
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')

tools.onCookieChange('sid', () => {
  const r = router as any
  if (r.history.current.name != 'login') router.push({ path: 'logout' })
})

function capitalizeFirstLetter(val) {
  return (
    String(val)
      .charAt(0)
      .toUpperCase() + String(val).slice(1)
  )
}
const theme = tools.getTheme()
document.body.classList.add('theme-' + theme)
const favicon = document.getElementById('favicon')
favicon.setAttribute('href', '/favicon-' + theme + '.ico?v=2')
document.title = capitalizeFirstLetter(theme)
export { eventBus }
