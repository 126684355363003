import { FieldWithItemsI, SkuFieldI } from '@/types/skuEditor'
import fieldFunctions from './skuEditorFunctions'
const choices: SkuFieldI[] = [
  { field: 'active', value: 'active', text: 'sku.active', type: 'boolean', formatter: null, filteringRule: null },
  { field: 'articleCode', value: 'articlecode', text: 'sku.articleCode', type: 'text', required: true, formatter: null, filteringRule: null },
  { field: 'articleCodeSupplier', value: 'articlecodesupplier', text: 'sku.articleCodeSupplier', type: 'text', formatter: null, filteringRule: null },
  { field: 'articleDescription', value: 'articledescription', text: 'sku.articleDescription', type: 'text', recommended: true, formatter: null, filteringRule: null },
  {
    field: 'articleGroup',
    value: 'articlegroup',
    text: 'sku.articleGroup',
    type: 'combobox',
    items: [],
    masterdata: true,
    formatter: null,
    filteringRule: null,
  },
  { field: 'articleGroupSupplier', value: 'articlegroupsupplier', text: 'sku.articleGroupSupplier', type: 'text', formatter: null, filteringRule: null },
  { field: 'barcode', value: 'barcode', text: 'sku.id', type: 'text', readOnly: true, required: true, formatter: null, filteringRule: null },
  { field: 'brand', value: 'brand', text: 'sku.brand', type: 'combobox', items: [], required: true, formatter: null, filteringRule: null },
  { field: 'buyPrice', value: 'buyprice', text: 'sku.buyPrice', type: 'currency', recommended: true, formatter: null, filteringRule: null },
  { field: 'calculation', value: 'calculation', text: 'sku.calculation', type: 'text', readOnly: true, formatter: null, filteringRule: null },
  {
    field: 'campaigns',
    value: 'campaigns',
    text: 'sku.campaigns',
    type: 'multiselect',
    items: [],
    formatter: fieldFunctions.campaignIdsToNames,
    filteringRule: null,
  },
  {
    field: 'collection',
    value: 'collection',
    text: 'sku.collection',
    type: 'combobox',
    items: [],
    recommended: true,
    masterdata: true,
    formatter: null,
    filteringRule: null,
  },
  { field: 'collectionSupplier', value: 'collectionsupplier', text: 'sku.collectionSupplier', type: 'text', formatter: null, filteringRule: null },
  { field: 'colorCode', value: 'colorcode', text: 'sku.colorCode', type: 'text', readOnly: true, formatter: null, filteringRule: null },
  { field: 'colorCodeSupplier', value: 'colorcodesupplier', text: 'sku.colorCodeSupplier', type: 'text', formatter: null, filteringRule: null },
  { field: 'colorDescription', value: 'colordescription', text: 'sku.colorDescription', type: 'text', readOnly: true, formatter: null, filteringRule: null },
  {
    field: 'colorFamily',
    value: 'colorfamily',
    text: 'sku.colorFamily',
    type: 'combobox',
    items: [],
    recommended: true,
    masterdata: true,
    formatter: null,
    filteringRule: null,
  },
  { field: 'colorSupplier', value: 'colorsupplier', text: 'sku.colorSupplier', type: 'text', formatter: null, filteringRule: null },
  { field: 'gln', value: 'gln', text: 'sku.gln', type: 'text', formatter: null, filteringRule: null },
  { field: 'images', value: 'imagename', text: 'sku.imageName', type: 'text', formatter: null, filteringRule: null },
  { field: 'images', value: 'images', text: 'sku.images', type: 'image', formatter: fieldFunctions.countImages, filteringRule: null },
  { field: 'margin', value: 'margin', text: 'sku.margin', type: 'text', readOnly: true, formatter: null, filteringRule: null },
  { field: 'mainSize', value: 'mainsize', text: 'sku.mainSize', type: 'text', readOnly: true, formatter: null, filteringRule: null },
  { field: 'pricat', value: 'pricat', text: 'sku.pricat', type: 'text', readOnly: true, formatter: null, filteringRule: null },
  { field: 'price', value: 'sellprice', text: 'sku.sellPrice', type: 'currency', recommended: true, formatter: null, filteringRule: null },
  {
    field: 'pricetagLayouts',
    value: 'pricetaglayouts',
    text: 'sku.pricetagLayouts',
    type: 'multiselect',
    items: [],
    formatter: null,
    filteringRule: null,
  },
  {
    field: 'size',
    value: 'size',
    text: 'sku.size',
    type: 'combobox',
    items: [],
    masterdata: true,
    formatter: null,
    filteringRule: null,
  },
  {
    field: 'genderSupplier',
    value: 'genderSupplier',
    text: 'sku.genderSupplier',
    type: 'combobox',
    items: [],
    masterdata: true,
    formatter: null,
    filteringRule: null,
  },
  { field: 'sizeSupplier', value: 'sizesupplier', text: 'sku.sizeSupplier', type: 'text', formatter: null, filteringRule: null },
  { field: 'source', value: 'source', text: 'sku.source', type: 'text', readOnly: true, formatter: null, filteringRule: null },
  { field: 'subColorCode', value: 'subcolor', text: 'sku.subColor', type: 'text', readOnly: true, formatter: null, filteringRule: null },
  { field: 'subSize', value: 'subsize', text: 'sku.subSize', type: 'text', readOnly: true, formatter: null, filteringRule: null },
  { field: 'salePrice', value: 'saleprice', text: 'sku.salePrice', type: 'currency', formatter: null, filteringRule: null },
  {
    field: 'campaignSalesPrice',
    value: 'campaignsalesprice',
    text: 'sku.campaignsalesprice',
    type: 'currency',
    readOnly: true,
    formatter: null,
    filteringRule: null,
  },
  { field: 'vatCategory', value: 'vat', text: 'sku.vat', type: 'enum', items: [], formatter: null, filteringRule: null },
  { field: 'quickPick', value: 'quickPick', text: 'sku.quickPick', type: 'number', formatter: null, filteringRule: null },
  {
    field: 'salesChannels',
    value: 'salesChannels',
    text: 'sku.salesChannels',
    type: 'multiselect',
    items: [],
    formatter: null,
    filteringRule: null,
  },
  {
    field: 'set',
    value: 'set',
    text: 'sku.set',
    type: 'combobox',
    items: [],
    masterdata: true,
    formatter: null,
    filteringRule: null,
  },
  { field: 'FEDASGROUP', value: 'fedasgroup', text: 'sku.FEDASGROUP', type: 'text', formatter: null, filteringRule: null },
  { field: 'BTEGROUP', value: 'btegroup', text: 'sku.BTEGROUP', type: 'text', formatter: null, filteringRule: null },
  { field: 'DTBGROUP', value: 'dtbgroup', text: 'sku.DTBGROUP', type: 'text', formatter: null, filteringRule: null },
  { field: '__modified', value: '__modified', text: 'sku.__modified', type: 'text', formatter: fieldFunctions.formatDate, filteringRule: null },
  { field: '__created', value: '__created', text: 'sku.__created', type: 'text', formatter: fieldFunctions.formatDate, filteringRule: null },
  { field: 'manufacturer', value: 'manufacturer', text: 'sku.manufacturer', type: 'text', formatter: null, filteringRule: null },
]

function updateItems() {
  choices.forEach((choice) => {
    const choiceWithItems = choice as FieldWithItemsI
    if (choiceWithItems.items) {
      choiceWithItems.items = fieldFunctions.getItems(choice.field)
    }
  })
}

export { choices, updateItems }
