import success from '@/assets/sounds/success.mp3'
import failed from '@/assets/sounds/failed.mp3'
import store from '@/store/index'

/**
 * Returns `void`.
 *
 * Play a local success.mp3 file as a sign for a succeed process.
 */
function playSucceed(): void {
  if (store.state.activeConfig.UISettings?.audioFeedback?.value) {
    new Audio(success).play()
  }
}

/**
 * Returns `void`.
 *
 * Play a local failed.mp3 file as a sign for a failed process.
 */
function playFailed(): void {
  if (store.state.activeConfig.UISettings?.audioFeedback?.value) {
    new Audio(failed).play()
  }
}

export default {
  playSucceed,
  playFailed,
}
