import { articleStatus } from '@softwear/latestcollectioncore'
import { parse } from 'date-fns'
import { compileExpression } from 'filtrex'

// Small helper function to intercept 'coded' values that cannot be parsed into a float
function getExactValue(value, FILTER_SYMBOLS) {
  // These cases should be caught in the filter
  if (FILTER_SYMBOLS.includes(value.substring(0, 1))) return value
  return parseFloat(value)
}

// Function testing if a row passes the filter
// Not returning anything means the row passes the filter
// Can still return a bool
// It is weird for efficiency reasons
const FILTER_SYMBOLS = ['!', '<', '>', '-', '>=', '<=']

function headerColumnFilter(row, key: string, searchString: string): void | boolean {
  const value = row[2][key]

  const isADate = ['v27'].includes(key)
  const searchDate = searchString

  const bounds = searchString.split('..')
  const isARange = searchString.includes('..')
  const exactValue = getExactValue(searchString, FILTER_SYMBOLS)
  const isExactDate = isADate && !isARange

  // A search without a range
  if (!isARange) {
    // All exceptions should match the FILTER_SYMBOLS
    if (typeof exactValue == 'string') {
      if (exactValue == '-') return value < 0

      // For any element of the FILTER_SYMBOLS and any element followed by "-" return true.
      // We do not want to hide the data while the user types '>=-'
      if (FILTER_SYMBOLS.some((elem) => elem === exactValue || `${elem}-` === exactValue)) return true

      // Deal with the symbols
      if (exactValue.startsWith('!')) return value != parseFloat(exactValue.substring(1))

      if (exactValue.startsWith('<=')) return value <= parseFloat(exactValue.substring(2))
      if (exactValue.startsWith('>=')) return value >= parseFloat(exactValue.substring(2))

      if (exactValue.startsWith('<')) return value < parseFloat(exactValue.substring(1))
      if (exactValue.startsWith('>')) return value > parseFloat(exactValue.substring(1))
    }
    if (value != exactValue) return false
  }

  // A search with a range
  // Determine the bounds
  // --------------------
  let start, end

  if (isExactDate) {
    // starts at filter date, ends one day later
    start = parse(searchDate, 'yyyy-MM-dd', new Date()).getTime()
    end = start + 24 * 3600 * 1000
    if (value >= start && value <= end) return true
  }

  if (isADate && isARange)
    // it is a range, so we parse the bounds. Add a day to the end date to make it inclusive
    [start, end] = [parse(bounds[0], 'yyyy-MM-dd', new Date()).getTime(), parse(bounds[1], 'yyyy-MM-dd', new Date()).getTime() + 24 * 3600]

  if (!isADate) [start, end] = [parseFloat(bounds[0]), parseFloat(bounds[1])]

  // Filter out rows that don't match the filter
  // -------------------------------------------
  if (searchString.startsWith('..')) return value <= end
  if (searchString.endsWith('..')) return value >= start

  if (isARange && (value < start || value > end)) return false
}

/**  This function has to be efficient, it is called for every row
// We iterate once and filter groupers first, then columns, then custom filter
// The order is not random, it is based on the fact that we want to filter out as many rows as possible as soon as possible

// For groupers we use the FILTREX expression to filter, groupFiltrexExpression is a filter cb function

// For columns we want to react on the first flag that does not pass the filter
// We look for the first 'false' - meaning the row does not pass the filter
// Then we exit the loop but `some()` will return true so we negate that

// We can take advantage of a custom filtering calback function
// We have a pretty random way of filtering, for now we check whether there is a 'Σ' symbol in the key
*/
function filterUIDataTableRows(rows, groupFiltrexExpression, columnsFilter: string[], customFilter: (row: any, showSubtotals: boolean) => boolean, showSubtotals: boolean) {
  const columnsFilterArray = Object.entries(columnsFilter)
  return rows.filter((oneAggregatedRow) => {
    return (
      (groupFiltrexExpression ? groupFiltrexExpression(oneAggregatedRow[2]) : true) &&
      !columnsFilterArray.some(([key, searchString]) => headerColumnFilter(oneAggregatedRow, key, searchString) == false) &&
      customFilter(oneAggregatedRow, showSubtotals)
    )
  })
}

function buildTableUIData(aggregations, selectedFields, headers, dates, appFilter = '') {
  const intermediateAggregations = []
  const makeFootprintCalculations = headers[0]?.field == 'sku.brand' && headers[1]?.field == 'sku.FOOTPRINT'
  const filterOptions = {}
  const groupers = {}
  const timeFrame = articleStatus.getDateRangeFromPicker(dates).timeFrame
  Object.entries(aggregations)
    // appFilter filters only groupers
    .filter((oneAggregatedRow) => !appFilter || oneAggregatedRow[0].toLowerCase().includes(appFilter))
    .forEach((oneAggregatedRow) => {
      const key = oneAggregatedRow[0]
      const result = [key, null]

      const vector = [].slice.call(oneAggregatedRow[1]) // vector is now a normal Array instead of a Float64Array so can be extended with additional fields
      articleStatus.postAgg(vector, timeFrame)

      const row = {} as any

      key.split('\t').forEach((item, index) => {
        const grouperValue = item == 'undefined' ? '' : item
        const grouperIndex = 'g' + index

        row[grouperIndex] = grouperValue
        // Add the item to the filterOptions if grouperValue is not already there
        if (!groupers[grouperIndex]) groupers[grouperIndex] = {}
        if (!groupers[grouperIndex][grouperValue]) groupers[grouperIndex][grouperValue] = 0
        // if there is already an entry, do nothing
      })

      selectedFields.forEach((field) => {
        row['v' + field] = vector[field]
        if (makeFootprintCalculations) row['v' + field + 1000] = vector[field] / parseFloat(row.g1)
      })
      if (key == '** TOTAL **') row.class = 'font-weight-black'
      if (key.endsWith('Σ')) row.class = 'subtotal'
      result[2] = row
      intermediateAggregations.push(result)
    })

  // filterOptions is a map of all possible values for each group column, translates to dropdown items in the grouper UI filter
  // For groupers `brand` and `collections` the form is:
  // { g0: { poools: 0, adidas: 0, 'value2': 0, ... }, g1: { summer20: 0, winter22: 0, 'value2': 0, ... }, ...
  // we are not interested in the '0's but the keys, we use them for lookups
  Object.keys(groupers).forEach((key) => {
    filterOptions[key] = Object.keys(groupers[key])
  })

  return { intermediateAggregations, filterOptions }
}

function buildGroupersAndColumnsFilter(headerFilters: string[]) {
  const columnsFilter = {}
  const groupersFilter = {}

  for (const [key, value] of Object.entries(headerFilters)) {
    if (value === '' || value === null) continue

    if (key.startsWith('g')) {
      Array.isArray(value) ? (groupersFilter[key] = value) : (groupersFilter[key] = value.toLowerCase())
      continue
    }

    columnsFilter[key] = value.toLowerCase()
  }
  return { columnsFilter, groupersFilter }
}

function isGroupColumn(column: string) {
  if (!column) return false
  return column[0] == 'g'
}

function compileFiltrexExpression(groupersFilters) {
  const filter = Object.entries(groupersFilters)
    // Filter out empty arrays and null values
    // filterValue looks like an array, its a `vue` object (an observer with an array constructor)
    // Check if the value is an object (array like) with 0 length OR the value is null, then inverse the result to satisfy the filter api
    // Build the filter string for the FiltreX compiler: (lower(g0) ~= "foo") and (g1 ~= "bar")
    // @ts-ignore
    .filter(([, filterValue]) => !((typeof filterValue === 'object' && filterValue.length === 0) || filterValue === null))
    .map(([key, filterValue]) => {
      let value
      if (Array.isArray(filterValue) && filterValue.length) value = `(${key} in (${filterValue.map((v) => `"${v}"`).join(',')}))`
      if (typeof filterValue === 'string') value = `(lower(${key}) ~= "${filterValue}")`

      return value
    })
    .join(' and ')

  // The filter is an empty string only if the user has cleared all filters, so show everything
  // Otherwise compile the filter expression
  return filter === '' ? undefined : compileExpression(filter, articleStatus.filtrexOptions)
}

function prepareGroupsForVisualization(reportViz, groups) {
  if (reportViz == 'matrix')
    return groups
      .filter((g) => !['sku.articleCodeSupplier', 'sku.articleDescription', 'transaction.ean'].includes(g))
      .concat(['sku.articleCodeSupplier', 'sku.articleDescription', 'transaction.ean'])
  if (reportViz == 'cards')
    return ['sku.articleCodeSupplier', 'sku.articleDescription', 'sku.brand'].concat(
      groups.filter((g) => !['sku.articleCodeSupplier', 'sku.articleDescription', 'sku.brand'].includes(g))
    )
  return groups
}

function createSignFilter(showNegativeValues, showPositiveValues) {
  if (showNegativeValues === showPositiveValues) return (key, value) => true
  if (showNegativeValues) return (key, value) => value < 0
  if (showPositiveValues) return (key, value) => value > 0
  return (key, value) => value !== 0
}

// Should be used for filtering at cell level
// Pass an array of callback functions to filter the aggregations
// It's an AND filter, all functions must return true for the aggregation to be included
function filterAggregations(aggregations, filters = []) {
  const result = {}
  for (const key in aggregations) {
    const value = aggregations[key]
    if (filters.every((filter) => filter(key, value))) result[key] = value
  }
  return result
}

function getRawDataFromAggregations(aggregations, dates, selectedFields) {
  if (!aggregations) return []
  const dateRange = articleStatus.getDateRangeFromPicker(dates)
  const timeFrame = dateRange.timeFrame

  for (const key in aggregations) {
    const vector = [].slice.call(aggregations[key])
    articleStatus.postAgg(vector, timeFrame)

    aggregations[key] = vector[selectedFields[0]]
  }

  delete aggregations['** TOTAL **']
  return aggregations
}

// This function should help with pagination and app-level filtering
function inferIndexes(filteredInputSkus) {
  const skusPerMatrix = {}
  let keyIndexWithDescription = {} // This object is used to be able to search also by description
  for (const key in filteredInputSkus) {
    const keyArr = key.split('\t')
    const matrixKey = keyArr.slice(0, -2).join('\t')
    keyIndexWithDescription[keyArr.slice(0, -1).join('\t')] = matrixKey
    const inputBarcode = { barcode: keyArr[keyArr.length - 1], qty: filteredInputSkus[key] }
    if (!skusPerMatrix[matrixKey]) skusPerMatrix[matrixKey] = [inputBarcode]
    else skusPerMatrix[matrixKey].push(inputBarcode)
  }

  const keyIndex = Object.keys(skusPerMatrix)
    .filter((key) => !key.includes('~ ?'))
    .sort((a, b) => (a < b ? -1 : 1))

  keyIndexWithDescription = Object.keys(keyIndexWithDescription)
    .filter((key) => !key.includes('~ ?'))
    .sort((a, b) => (a < b ? -1 : 1))
    .reduce((acc, key) => {
      acc[key] = keyIndexWithDescription[key]
      return acc
    }, {})

  return { skusPerMatrix, keyIndex, keyIndexWithDescription }
}

export default {
  getRawDataFromAggregations,
  prepareGroupsForVisualization,
  compileFiltrexExpression,
  filterUIDataTableRows,
  filterAggregations,
  createSignFilter,
  buildTableUIData,
  buildGroupersAndColumnsFilter,
  isGroupColumn,
  inferIndexes,
}
