import tools from './tools'
import axios from 'axios'
import pako from 'pako'
import consts from '../store/consts'
import cookies from 'js-cookie'
import axiosRetry from 'axios-retry'
import { v4 as uuidv4 } from 'uuid'
import { showDialog } from './dialogService'

const retryRequest = function(err) {
  if (process.env.NODE_ENV === 'development' && err.config.url.includes('softwear.nl')) return false
  const retry = err.message == 'Network Error' || err.response.status >= 500
  return retry
}
axiosRetry(axios, { retries: 10, retryDelay: axiosRetry.exponentialDelay, retryCondition: retryRequest })

const getStockSnapshot = async function(id) {
  // Use fetch because in this case, we don't want the axios retry mechanism to ckick in
  const response = await fetch(`${consts.softwear.stockRoot}/${id}/stocksnapshot.json`)
  return await response.json()
}

// Setup Axios middleware to compress requests using pako gzip library
const startupDBApi = axios.create({
  baseURL: consts.webServicesPath + '/data',
  transformRequest: (axios.defaults.transformRequest as Array<any>).concat(function(data, headers) {
    if (!(process.env.NODE_ENV === 'production' && typeof data === 'string' && data.length > 1024)) return data
    // compress strings if over 1KB
    headers['Content-Encoding'] = 'gzip'
    return pako.gzip(data)
  }),
})
axiosRetry(startupDBApi, { retries: 10, retryDelay: axiosRetry.exponentialDelay, retryCondition: retryRequest })

let dbaPWD = ''
const dbaGetAllCollections = async function() {
  dbaPWD = (
    await showDialog('swPromptDialog', {
      title: 'DBA',
      message: 'Enter DBA password',
      dataTest: 'dba',
      type: 'password',
      placeholder: 'Password',
    })
  ).toString()
  return startupDBApi.get('', { params: { token: dbaPWD } })
}
const dbaRunQuery = function(collection, query) {
  return startupDBApi.get(collection, {
    params: { token: dbaPWD, filter: query, cacheBuster: uuidv4() },
  })
}
const dbaRunCommand = function(command) {
  return startupDBApi.post('', command, {
    params: { token: dbaPWD },
    headers: { 'Content-Type': 'application/json' },
  })
}

const dbaPut = function(collection, document, params) {
  return startupDBApi.put(collection, document, {
    params: { ...params, token: dbaPWD },
  })
}

const dbaDelete = function(collection, document) {
  return startupDBApi.delete(collection, {
    params: { token: dbaPWD, id: document.id },
  })
}

const latestCollectionGet = async function(endpoint) {
  return startupDBApi.get(endpoint, tools.apiHeaders())
}

const latestCollectionPost = async function(endpoint, data) {
  return startupDBApi.post(endpoint, data, tools.apiHeaders())
}
const latestCollectionPut = async function(endpoint, data) {
  return startupDBApi.put(endpoint, data, tools.apiHeaders())
}

const latestCollectionPatch = async function(endpoint, data) {
  return startupDBApi.patch(endpoint, data, tools.apiHeaders())
}

const getSku = async function(state, barcode, lookupOnly) {
  if (isNaN(barcode)) return { data: '' }
  const tenantId = state.user.current_tenant
  if (lookupOnly) return latestCollectionGet(`/${tenantId}/sku?id=${barcode}&lookupOnly`)
  return latestCollectionGet(`/${tenantId}/sku?id=${barcode}`)
}

const getMe = async function() {
  return latestCollectionGet('/me')
}

const getTenant = async function(tenantId: string) {
  return latestCollectionGet(`/tenant?id=${tenantId}`)
}
const setTenant = async function(data) {
  return latestCollectionPatch('/tenant', data)
}
enum MessageTypes {
  missingBarcode = 'missingBarcode',
}
interface Message {
  sender: string
  receiver: string
  type: MessageTypes
  body: object
}
const sendToOutbox = function(message: Message) {
  return axios.post(`${consts.webServicesPath}/service/outbox`, message, tools.apiHeaders())
}

const regenerateBI = function(tenantId, version) {
  return axios.post(`${consts.webServicesPath}/service/regeneratebi`, { tenantId, version }, tools.apiHeaders())
}
/**
 *
 * getSchema intentionally does not use startupDBApi
 * since it is only supposed to run against a dev database
 */
const getSchema = function(schema) {
  return axios.get(`${consts.webServicesPath}/v1/schema/${schema}.json`)
}
const postImage = function(file: Blob, fileName: string) {
  // Create a FormData object
  const formData = new FormData()
  formData.append('file', file, fileName)
  if (process.env.NODE_ENV === 'production') axios.post(consts.softwear.fotoManagerUrl + '?id=' + consts.tenant.feedId, formData)
  else axios.post(consts.softwear.fotoManagerUrl + '?id=' + '123', formData)
}

const getActivePOS = function() {
  return axios.get(consts.webServicesPath + '/service/pos-active', tools.apiHeaders())
}

const getSpynlMetrics = function() {
  return axios.get(consts.webServicesPath + '/service/spynl-metrics', tools.apiHeaders())
}

const getSpynlSales = function(source, endDate, startDate, group_by?) {
  if (group_by) {
    return axios.get(consts.webServicesPath + `/service/spynl-sales?doc=${source}&endDate=${endDate}&group_by=${group_by}&startDate=${startDate}`, tools.apiHeaders())
  } else {
    return axios.get(consts.webServicesPath + `/service/spynl-sales?doc=${source}&endDate=${endDate}&startDate=${startDate}`, tools.apiHeaders())
  }
}

const getLATConnections = function() {
  return axios.get(consts.webServicesPath + '/service/pos-stats', tools.apiHeaders())
}

const getM2FEvents = function() {
  return axios.get(consts.webServicesPath + '/service/spynl-events', tools.apiHeaders())
}

const getNPData = function(source) {
  return axios.get(consts.webServicesPath + `/service/np?doc=${source}`, tools.apiHeaders())
}

const getPicqerData = function(source) {
  return axios.get(consts.webServicesPath + `/service/picqer?doc=${source}`, tools.apiHeaders())
}

const getTenantById = function(tenantId) {
  return latestCollectionGet(`/tenant?id=${tenantId}`)
}

const getAssociatesWithOwners = function(brandID, tenants) {
  const token = cookies.get('sid')
  return axios.post(
    `${consts.webServicesPath}/service/beforeGetTenantsWithOwners?token=${token}`,
    {
      id: brandID,
      tenants: tenants,
    },
    {
      ...tools.apiHeaders(),
      validateStatus: () => true,
    }
  )
}

const sendPurchaseOrder = async function(id, pdf) {
  return axios.post(`${consts.webServicesPath}/service/sendPurchaseOrder`, { id: id, pdf: pdf }, tools.apiHeaders())
}

const getOrdersFromAccountView = function() {
  return axios.get(consts.webServicesPath + `/service/get-orders-from-accountview`, tools.apiHeaders())
}

const syncPOs = async function(numDays, store) {
  return axios.get(`${consts.webServicesPath}/data/${store.state.user.current_tenant}/purchaseorder?days=${numDays}`, tools.apiHeaders())
}

const getTools = async function() {
  return axios.get(`${consts.webServicesPath}/data/tool`, tools.apiHeaders())
}

const syncResource = async function(resource: string, store, params?) {
  return axios.post(`${consts.webServicesPath}/service/sync`, { resource: resource, tenant: store.state.user.current_tenant }, { ...tools.apiHeaders(), params })
}

const impersonate = function(tenantId) {
  return axios.post(consts.webServicesPath + `/service/impersonate`, { tenantId: tenantId }, tools.apiHeaders())
}

const resetDbaPWD = function() {
  dbaPWD = ''
}

async function forceDownload(url, fileName) {
  let blob
  try {
    blob = await axios.get(url, { responseType: 'blob' })
  } catch (error) {
    try {
      blob = await axios.get(tools.imageUrlParse(url, 1000), { responseType: 'blob' })
    } catch (error) {
      return false
    }
  }
  const urlCreator = window.URL || window.webkitURL
  const imageUrl = urlCreator.createObjectURL(blob.data)
  const tag = document.createElement('a')
  tag.href = imageUrl
  tag.download = fileName
  document.body.appendChild(tag)
  tag.click()
  document.body.removeChild(tag)
  return true
}

const getSkuImportHistory = function(brand) {
  const token = cookies.get('sid')
  return axios.get(`${consts.webServicesPath}/service/sku-import-history?brand=${brand}&token=${token}`)
}
const getSkuImportHistoryFile = function(brand, fileName) {
  const token = cookies.get('sid')
  return axios.get(`${consts.webServicesPath}/service/sku-import-history/download?brand=${brand}&filename=${fileName}&token=${token}`, { responseType: 'blob' })
}
const importSkuFileToArchive = function(brand, collection, blob) {
  const token = cookies.get('sid')
  return axios.post(
    `${consts.webServicesPath}/service/sku-import-history?token=${token}`,
    {
      brand: brand,
      collection: collection,
      blob: blob,
    },
    tools.apiHeaders()
  )
}

const sendToPrintQueue = function(queueName, payload) {
  return axios.post(`https://pq2.softwearconnect.com/queue?queue=${queueName}`, payload)
}

const getTokens = async function(tenantId) {
  if (tenantId == 'master') return latestCollectionGet(`/token`)
  return latestCollectionGet(`/token?filter=data.current_tenant=="${tenantId}"`)
}

const createToken = async function(tenantId: string, description: string) {
  return latestCollectionPost(`/token`, { tenantId: tenantId, description: description })
}

const createPricat = async function(tenantId: string, items: any[], remark: string) {
  return latestCollectionPost(`${tenantId}/pricat`, { items: items, remark: remark })
}
const createSku = async function(tenantId: string, skus: any[]) {
  return latestCollectionPut(`${tenantId}/sku`, skus)
}
const createPurchaseOrder = async function(tenantId: string, items: any[], brand: string, date: number, warehouse: string) {
  return latestCollectionPost(`${tenantId}/purchaseorder`, { items: items, brand: brand, date: date, warehouseId: warehouse })
}

const revokeToken = async function(tokenId: string) {
  return latestCollectionPatch(`/token`, { id: tokenId, patch: [{ op: 'replace', path: '/active', value: false }] })
}

const getImagesFromCdn = function(brandId) {
  return axios.get(`${consts.webServicesPath}/service/imagecdn?brand=${brandId}`, tools.apiHeaders())
}
const getFpSkus = async function(id) {
  // Use fetch because in this case, we don't want the axios retry mechanism to ckick in
  return await axios.get(`${consts.softwear.stockRoot}/${id}/fpfeed.js`)
}

const sendReceivingToFp = async function(id: string, tenant: string) {
  return axios.post(`${consts.webServicesPath}/service/sendReceivingToFP`, { id: id, tenant: tenant }, tools.apiHeaders())
}

const sendProcessedInventoryChange = function(document: any) {
  const token = cookies.get('sid')

  return axios.post(
    `${consts.webServicesPath}/service/sendInventorychange?token=${token}`,
    {
      document: document,
    },
    {
      ...tools.apiHeaders(),
      validateStatus: () => true,
    }
  )
}

export default {
  resetDbaPWD,
  getSku,
  getMe,
  getTenant,
  setTenant,
  getStockSnapshot,
  dbaGetAllCollections,
  dbaRunQuery,
  dbaRunCommand,
  dbaPut,
  dbaDelete,
  forceDownload,
  getSchema,
  startupDBApi,
  postImage,
  getActivePOS,
  getSpynlMetrics,
  getSpynlSales,
  getLATConnections,
  getM2FEvents,
  regenerateBI,
  sendToOutbox,
  getNPData,
  getPicqerData,
  getTenantById,
  getTokens,
  createToken,
  revokeToken,
  getAssociatesWithOwners,
  getOrdersFromAccountView,
  sendPurchaseOrder,
  getSkuImportHistory,
  getSkuImportHistoryFile,
  importSkuFileToArchive,
  syncPOs,
  impersonate,
  sendToPrintQueue,
  getImagesFromCdn,
  getFpSkus,
  syncResource,
  sendReceivingToFp,
  sendProcessedInventoryChange,
  getTools,
  createPricat,
  createSku,
  createPurchaseOrder,
}
