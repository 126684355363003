<template>
  <div>
    <swProductCard :hover="hover" :product="selectedProduct">
      <template #card-header="{ product }">
        <div class="px-0 d-flex align-center" :class="headerClass(product.usedByTenant)" style="height:36px" @click="handleShowYesNoDialog(product.usedByTenant)">
          <div v-if="product.usedByTenant !== true && !isDemo" style="width:20px">&nbsp;</div>
          <div class="ellipsis caption white--text">
            <span>{{ product.articleCodeSupplier }}</span>
          </div>
          <v-icon v-if="product.usedByTenant !== true && !isDemo && !isAdmin && !isB2B && !isDataOnly" dark dense class="headerIcon">
            mdi-cloud-download-outline
          </v-icon>
        </div>
      </template>

      <template #card-image="{ product }">
        <div class="d-flex flex-column justify-center align-center" style="height:200px" @click="imageAction">
          <v-img v-if="product.IMAGES[0]" contain height="200" width="150" :src="imageSrc(product.IMAGES[0].url, 150)" />
          <v-img v-else-if="groupIconName(product)" :src="require(`../assets/groupsIcons/${groupIconName(product)}.svg`)" contain width="100"></v-img>
        </div>
      </template>
    </swProductCard>
  </div>
</template>

<script>
import swProductCard from '../components/swProductCard.vue'
import tools from '../functions/tools'
import consts from '../store/consts.ts'
import userFunctions from '../functions/users'
import { swT } from '@/functions/i18n'
import { showDialog } from '../functions/dialogService.ts'

export default {
  components: {
    swProductCard,
  },
  props: ['selectedProduct', 'hover'],
  data() {
    return {
      groupsIcons: consts.groupsIcons,
      update: 0,
    }
  },
  computed: {
    isDemo() {
      return this.$store.getters.isDemo
    },
    isAdmin() {
      if (userFunctions.hasRole(this.$store.getters.roles, 'sw,products-dataprovider_admin')) return true
      return false
    },
    isB2B() {
      if (userFunctions.hasRole(this.$store.getters.roles, 'lc-b2b')) return true
      return false
    },
    isDataOnly() {
      if (userFunctions.hasRole(this.$store.getters.roles, 'lc-data-only')) return true
      return false
    },
  },
  methods: {
    groupIconName(product) {
      if (!product.articleGroup) return ''
      const groupName = product.articleGroup.toLowerCase()
      if (!this.groupsIcons.includes(groupName)) return ''
      return groupName
    },
    headerClass(usedByTenant) {
      if (this.isDemo) return 'justify-center'
      return usedByTenant ? 'justify-center' : 'justify-space-between'
    },
    async handleShowYesNoDialog(usedByTenant) {
      if (this.isDemo) return
      if (usedByTenant) {
        this.imageAction()
        return
      }
      // If the user remembered his confirmation, download directly.
      if (sessionStorage.getItem('rememeberProductDownloadConfirmation') === 'true') this.downloadProduct()
      // Otherwise, show the yes no dialog
      else {
        const answer = await showDialog('swYesNoDialog', { message: swT('product_download_confirmation'), showRemember: true })
        if (!answer || !answer.answer) return
        if (answer.remember) sessionStorage.setItem('rememeberProductDownloadConfirmation', true)
        this.downloadProduct()
      }
    },
    downloadProduct() {
      if (this.isDemo || this.isAdmin) this.$emit('update:selected', this.selectedProduct)
      this.$store.state.loading = true
      this.saveProductToTenantDb(this.selectedProduct)
    },
    imageAction() {
      this.$emit('update:selected', this.selectedProduct)
    },
    async saveProductToTenantDb() {
      this.selectedProduct.SKUS.forEach((sku) => {
        if (sku.articleGroup && !sku.articleGroupSupplier) sku.articleGroupSupplier = sku.articleGroup
        if (sku.size && !sku.sizeSupplier) sku.sizeSupplier = sku.size
        if (sku.collection && !sku.collectionSupplier) sku.collectionSupplier = sku.collection
        delete sku.articleGroup
        delete sku.size
        delete sku.collection
      })

      await this.$store.dispatch('updateSkuObjects', { data: this.selectedProduct.SKUS, audit: 'Product card' })

      this.$forceUpdate()
      this.$store.state.loading = false
    },
    imageSrc(imageUrl, imageWidth) {
      return tools.imageUrlParse(imageUrl, imageWidth)
    },
  },
}
</script>
