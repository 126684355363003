<template>
  <div class="mt-2">
    <div v-for="(matrix, id) in onScreenData" :key="matrix.SKUS[0].articleCodeSupplier + id">
      <swMatrixProduct :product="matrix" :readonly="true" :show-eye="true" :hide-zeroes="hideZeroes" :show-image="showImage">
        <template #left="{ leftProps }">
          <v-card-title class="pa-0 d-flex flex-column align-start">
            <div class="d-flex flex-row align-start">
              <div v-for="(grouper, i) in leftProps.groupers" :key="i" class="pr-2 pb-2 mr-2 rounded-lg">
                <span class="caption">{{ swT(selectedGroups[i]) }}</span>
                <h4 class="font-weight-medium mt-n2">{{ grouper }}</h4>
              </div>
            </div>
            <div>
              <h2>{{ leftProps.SKUS[0].articleCodeSupplier }}</h2>
              <h3>{{ leftProps.SKUS[0].articleDescription }}</h3>
            </div>
          </v-card-title>
        </template>
      </swMatrixProduct>
    </div>
    <swScrollTopFab />
  </div>
</template>

<script>
import swScrollTopFab from '../components/swScrollTopFab.vue'
import globalStore from '../store/globalStore'
import productFunctions from '../functions/products'
import swMatrixProduct from '../components/swMatrixProduct.vue'
import { eventBus } from '../main'
import { swT } from '@/functions/i18n'
import reports from '../functions/reportVisualizationFunctions'

export default {
  components: { swScrollTopFab, swMatrixProduct },
  props: ['selectedFields', 'fieldChoices', 'dates', 'selectedGroups', 'itemsPerPage', 'hideZeroes', 'showImage', 'showNegativeValues', 'showPositiveValues'],
  data() {
    return {
      swT,
      fields: {},
      barcodesToFilterBy: [],
      skusPerMatrix: {},
      inputSkus: {},
      onScreenData: [],
      localKeyIndex: [],
      localKeyIndexWithDescription: {},
    }
  },
  computed: {
    lowerCaseFilter() {
      return this.$store.state.filter?.toLowerCase() || ''
    },
  },
  watch: {
    '$store.state.filter': {
      handler() {
        const filteredKeyIndex = this.filterKeyIndex()
        this.calculateTotalPages(filteredKeyIndex)
        const onScreenIndex = this.getCurrentPageMatrices(filteredKeyIndex)
        this.onScreenData = this.build(onScreenIndex)
      },
    },
    showPositiveValues() {
      this.aggregationsChangeLocally()
    },
    showNegativeValues() {
      this.aggregationsChangeLocally()
    },
    itemsPerPage() {
      this.calculateTotalPages(this.localKeyIndex)
      const onScreenIndex = this.getCurrentPageMatrices(this.localKeyIndex)
      this.onScreenData = this.build(onScreenIndex)
    },
    '$store.state.paginationCurrentPage': {
      handler() {
        const onScreenIndex = this.getCurrentPageMatrices(this.localKeyIndex)
        this.onScreenData = this.build(onScreenIndex)
      },
    },
  },
  created() {
    eventBus.$on('updateVisualizations', (aggregations) => {
      this.transformRawAgg(aggregations, this.dates, this.selectedFields)
    })
    this.fields = this.fieldChoices.reduce((agg, item) => {
      agg[item.value] = item.text
      return agg
    }, {})
  },
  beforeDestroy() {
    eventBus.$off('updateVisualizations')
  },
  methods: {
    transformRawAgg(aggregations, dates, selectedFields) {
      this.inputSkus = reports.getRawDataFromAggregations(aggregations, dates, selectedFields)
      this.aggregationsChangeLocally()
    },
    filterInputSkus(inputSkus, showNegativeValues, showPositiveValues) {
      return reports.filterAggregations(inputSkus, [reports.createSignFilter(showNegativeValues, showPositiveValues)])
    },
    inferIndexes(inputSkus) {
      return reports.inferIndexes(inputSkus)
    },
    filterKeyIndex() {
      return Object.keys(this.localKeyIndexWithDescription)
        .filter((key) => key.toLowerCase().includes(this.lowerCaseFilter))
        .map((key) => this.localKeyIndexWithDescription[key])
    },
    getCurrentPageMatrices(keyIndex) {
      const start = (this.$store.state.paginationCurrentPage - 1) * this.itemsPerPage
      const end = start + this.itemsPerPage
      return keyIndex.slice(start, end)
    },
    build(onScreenIndex) {
      const onScreenData = []
      onScreenIndex.forEach((product) => {
        const t = productFunctions.buildMatrices(
          this.skusPerMatrix[product],
          globalStore.getLatestCollectionObject('sku'),
          globalStore.getIndexedProducts(),
          (product) => product.SKUS.find((sku) => sku.GROUPED)?.GROUPED?.slice(-3, -1),
          globalStore.getMetadata().sizeOrdering.data,
          this.$store.getters.mapSubSizes,
          'colorCodeSupplier',
          ['']
        )
        t[0].groupers = product.split('\t').slice(0, -1)
        onScreenData.push(t[0])
      })
      return onScreenData
    },
    aggregationsChangeLocally() {
      const filteredInputSkus = this.filterInputSkus(this.inputSkus, this.showNegativeValues, this.showPositiveValues)
      const { skusPerMatrix, keyIndex, keyIndexWithDescription } = this.inferIndexes(filteredInputSkus)
      this.skusPerMatrix = skusPerMatrix
      this.localKeyIndex = keyIndex
      this.localKeyIndexWithDescription = keyIndexWithDescription
      const filteredKeyIndex = this.filterKeyIndex()
      this.calculateTotalPages(filteredKeyIndex)
      const onScreenIndex = this.getCurrentPageMatrices(filteredKeyIndex)
      this.onScreenData = this.build(onScreenIndex)
    },
    calculateTotalPages(keyIndex) {
      eventBus.$emit('UIDataLengthHasChanged', keyIndex.length)
      this.$store.state.articleStatus.totalItems = keyIndex.length
      this.$store.state.paginationTotalPages = Math.ceil(keyIndex.length / this.itemsPerPage)
    },
  },
}
</script>
