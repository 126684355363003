<template>
  <v-card style="width:100%" class="my-2">
    <v-card-text>
      <v-row class="d-flex justify-space-between align-center py-2 sticky-2 white">
        <h2 class="pl-2">{{ swT('property_mapping') }}</h2>
        <div class="d-flex">
          <swButton data-test="addPropertyMapping" color="primary" :tooltip="swT('add_mapping_tooltip')" icon="mdi-plus-thick" @click="addPropertyMapping" />
          <div outlined class="d-flex align-center">
            <swButton
              data-test="addMissingPropertyMappings"
              color="primary"
              icon="mdi-auto-fix"
              :loading="loading"
              :disabled="loading"
              :tooltip="swT('add_missing_mappings_tooltip')"
              @click="addMissingPropertyMappings"
            />

            <div>
              {{ selectedPropertyMapping ? selectedPropertyMapping.category : selectACell }}
            </div>
          </div>
          <swButton
            v-if="isSWAdmin"
            data-test="importTsv"
            color="primary"
            icon="mdi-import"
            :loading="loading"
            :disabled="loading"
            :tooltip="swT('import_mapping_from_tsv')"
            @click="importMappingsFromTsv"
          />
        </div>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-simple-table dense fixed-header style="width:100%">
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="150px">
                    <div class="text-h6 pl-2">{{ swT('category') }}</div>
                  </th>
                  <th>
                    <div class="d-flex justify-space-between">
                      <div class="d-flex align-center pl-2">
                        <div class="text-h6">{{ swT('map_from') }}</div>
                      </div>
                      <div>
                        <v-text-field v-model="filterFrom" outlined dark dense hide-details class="pa-2" label="Filter"></v-text-field>
                      </div>
                    </div>
                  </th>
                  <th class="text-left">
                    <div class="d-flex justify-space-between">
                      <div class="d-flex align-center pl-2">
                        <div class="text-h6">{{ swT('map_to') }}</div>
                      </div>
                      <div>
                        <v-text-field v-model="filterTo" outlined dark dense hide-details class="pa-2" label="Filter"></v-text-field>
                      </div>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody id="propertyMappingTable">
                <tr v-for="(item, index) in paginatedRows" :key="index" :class="categoryColor(item.category)">
                  <td @click="selectedPropertyMapping = item">
                    <v-select
                      v-if="!item.from"
                      v-model="item.category"
                      class="d-flex align-center py-0 pl-2"
                      :items="activeMappingCategories"
                      hide-details
                      @update="selectedPropertyMapping = item"
                    ></v-select>
                    <span v-else>{{ item.category }}</span>
                  </td>
                  <td>
                    <div>
                      <v-text-field
                        v-model="item.from"
                        :data-test="`fromfield-${index}`"
                        class="d-flex align-center py-0 px-2 left"
                        hide-details
                        :class="selectToAutofix"
                        onfocus="this.select()"
                        @focus="selectedPropertyMapping = item"
                      ></v-text-field>
                    </div>
                  </td>
                  <td>
                    <v-text-field
                      v-if="widgetType(item.category, item.from) == 'textfield'"
                      v-model="item.to"
                      :data-test="`tofield-${index}`"
                      class="d-flex align-center py-0 px-2 left"
                      hide-details
                      onfocus="this.select()"
                      @focus="selectedPropertyMapping = item"
                    ></v-text-field>
                    <v-combobox
                      v-if="widgetType(item.category, item.from) == 'combobox'"
                      v-model="item.to"
                      class="d-flex align-center py-0 px-2 left"
                      hide-details
                      :items="item.from == '~~' ? getItems('masterbrands') : categoryItems[item.category]"
                      :data-test="`autocomplete-mapping-${index}`"
                      @focus="selectedPropertyMapping = item"
                    ></v-combobox>
                    <v-select
                      v-if="widgetType(item.category, item.from) == 'select'"
                      v-model="item.to"
                      class="d-flex align-center py-0 px-2 left"
                      hide-details
                      :items="item.from == '~~' ? getItems('masterbrands') : categoryItems[item.category]"
                      :data-test="`autocomplete-mapping-${index}`"
                      @focus="selectedPropertyMapping = item"
                    ></v-select>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn v-longpress="() => deleteRow(index, currentPage)" class="py-3 px-0 ma-0 error" data-test="deleteButton" x-small v-on="on">
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ swT('click_and_hold_to_delete') }}</span>
                      </v-tooltip>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-pagination v-model="currentPage" :length="pageCount"></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { swT } from '@/functions/i18n'
import swButton from '../components/swButton.vue'
import globalStore from '../store/globalStore'
import { MAPPING_CATEGORIES } from '@softwear/latestcollectioncore'
import userFunctions from '../functions/users'
import tools from '../functions/tools'
import productFunctions from '../functions/products'
import { showDialog } from '@/functions/dialogService'

export default {
  components: { swButton },
  props: ['mapping', 'brand'],
  data() {
    return {
      swT,
      valid: false,
      selectedPropertyMapping: null,
      categoryItems: {},
      MAPPING_CATEGORIES: MAPPING_CATEGORIES,
      loading: false,
      filterFrom: '',
      filterTo: '',
      filteredMapping: [],
      selectToAutofix: '',
      selectACell: '',
      currentPage: 1,
      rowsPerPage: 10,
    }
  },
  computed: {
    isSWAdmin() {
      if (userFunctions.hasRole(this.$store.getters.roles, 'sw')) return true
      return false
    },
    activeMappingCategories() {
      const brandId = this.brand
      if (brandId == 'ermmapping') return ['ERM']
      return MAPPING_CATEGORIES.filter((category) => category != 'ERM')
    },
    pageCount() {
      return parseInt(Object.keys(this.filteredMapping).length / this.rowsPerPage) + 1
    },
    paginatedRows() {
      const start = (this.currentPage - 1) * this.rowsPerPage
      const end = start + this.rowsPerPage
      return this.filteredMapping.slice(start, end)
    },
  },
  watch: {
    filterFrom: {
      handler: function() {
        this.filterMapping()
      },
      immediate: true,
    },
    filterTo: {
      handler: function() {
        this.filterMapping()
      },
      immediate: true,
    },
    selectedPropertyMapping: {
      handler: function(newVal) {
        if (newVal) {
          this.selectACell = ''
          this.selectToAutofix = ''
        }
      },
      immediate: true,
    },
  },
  created() {
    this.categoryItems = {
      Collection: this.$store.state.collections,
      ProductGroup: this.$store.state.productGroups,
      BTEGroup: this.$store.state.productGroups,
      DTBGroup: this.$store.state.productGroups,
      FedasGroup: this.$store.state.productGroups,
      Size: this.$store.state.sizes,
      ColorDescription: this.$store.state.colors,
      ColorCode: this.$store.state.colors,
      CustomSize: tools.getUniqueValues(globalStore.getLatestCollectionArray('sku').map((e) => e._customsize)).sort(),
      GenderSupplier: tools.getUniqueValues(globalStore.getLatestCollectionArray('sku').map((e) => e.genderSupplier)).sort(),
    }
  },
  methods: {
    filterMapping() {
      this.filteredMapping = this.mapping.filter((m) => {
        if (!this.filterFrom && !this.filterTo) return true

        const filter = m.from.toLocaleLowerCase().includes(this.filterFrom.toLocaleLowerCase()) && m.to.toLocaleLowerCase().includes(this.filterTo.toLocaleLowerCase())
        if (filter) this.currentPage = 1
        return filter
      })
    },
    getItems(items) {
      return globalStore.getItems(items, this.$store)
    },
    widgetType(category) {
      if (['ERM', 'SubSize'].includes(category)) return 'textfield'
      if (['fedasmapping', 'btemapping', 'dtbmapping'].includes(this.brand)) return 'combobox'
      if (this.isSWAdmin && ['Collection', 'ProductGroup'].includes(category)) return 'select'
      return 'combobox'
    },
    async addMissingPropertyMappings() {
      if (!this.selectedPropertyMapping) {
        this.selectACell = swT('please_select_a_cell_first')
        this.selectToAutofix = 'orange'
        return
      }
      this.selectACell = ''
      this.selectToAutofix = ''
      this.loading = true
      const category = this.selectedPropertyMapping.category
      const filteredMapping = this.mapping.filter((m) => m.category == category)
      const indexedMapping = filteredMapping.reduce((agg, item) => {
        agg[item.from] = item.to
        return agg
      }, {})
      const brandId = this.brand
      const brandSettings = productFunctions.getBrandSettings(brandId, this.$store.state.brands)
      let skus
      if (userFunctions.hasRole(this.$store.getters.roles, 'sw')) {
        await this.$store.dispatch('loadBrand', { brand: brandId, dataProvider: '' })
        skus = globalStore.getLatestCollectionArray('selectedbrand')
      } else if (userFunctions.hasRole(this.$store.getters.roles, 'products-dataprovider_admin')) {
        await this.$store.dispatch('loadBrand', { brand: brandId, dataProvider: this.$store.state.user.latestCollectionSettings.dataProviderAdmin })
        skus = globalStore.getLatestCollectionArray('selectedbrand')
      } else skus = globalStore.getLatestCollectionArray('sku')

      const mapFromField = {
        Size: 'sizeSupplier',
        ProductGroup: 'articleGroupSupplier',
        Collection: 'collectionSupplier',
        ColorCode: 'colorCodeSupplier',
        ColorDescription: 'colorSupplier',
        SubSize: 'sizeSupplier',
        CustomSize: 'sizeSupplier',
        GenderSupplier: 'genderSupplier',
      }
      const fromField = mapFromField[category]
      if (category == 'ERM' && brandId == 'ermmapping') {
        globalStore
          .getLatestCollectionArray('sku')
          .filter((sku) => sku['_gender'] && sku['articleGroup'])
          .sort((a, b) => (a['_gender'] + '.' + a['articleGroup'] < b['_gender'] + '.' + b['articleGroup'] ? -1 : 1))
          .forEach((sku) => {
            const fromValue = sku['_gender'] + '.' + sku['articleGroup']
            if (!fromValue || indexedMapping[fromValue] !== undefined) return
            indexedMapping[fromValue] = ''
            this.mapping.push({
              category: category || '',
              from: fromValue,
              to: sku['articleGroup'],
            })
          })
      } else
        skus
          .filter((sku) => productFunctions.isSkuOfBrand(sku, brandSettings.collection, brandSettings.aliases))
          .sort((a, b) => (a[fromField] < b[fromField] ? -1 : 1))
          .forEach((sku) => {
            const fromValue = sku[fromField]
            if (!fromValue || indexedMapping[fromValue] !== undefined) return
            indexedMapping[fromValue] = ''
            this.mapping.push({
              category: category || '',
              from: fromValue,
              to: '',
            })
          })
      this.loading = false
      this.filterMapping()
      this.goToLastPage()
      this.goToLastRow()
    },
    async importMappingsFromTsv() {
      if (!this.selectedPropertyMapping) {
        this.$store.dispatch('raiseAlert', {
          header: 'select_category',
          body: '',
          timeout: 3000,
          type: 'warning',
        })
        return
      }
      const tsv = await showDialog('swPromptDialog', {
        title: 'Mappings',
        message: 'Paste mappings as TSV',
        dataTest: 'mappings',
        placeholder: 'Mappings',
      })
      if (!tsv) return

      const category = this.selectedPropertyMapping.category
      const existingMappingIndex = this.mapping
        .filter((m) => m.category == category)
        .reduce((agg, m) => {
          agg[m.from] = m
          return agg
        }, {})
      tsv.split('\n').forEach((line) => {
        const splitLine = line.split('\t')
        const from = splitLine[0]
        const to = splitLine[1]
        const existingRule = existingMappingIndex[from]
        if (existingRule) existingRule.to = to
        else
          this.mapping.push({
            category: category || '',
            from: from,
            to: to,
          })
      })
      this.filterFrom = this.filterTo = ''
      this.goToLastRow()
    },
    addPropertyMapping() {
      const category = this.selectedPropertyMapping?.category
      this.mapping.push({
        category: category || '',
        from: '',
        to: '',
      })
      this.filterMapping()
      this.goToLastPage()
      this.goToLastRow()
    },
    deleteRow(indexOnPage, currentPage) {
      const index = (currentPage - 1) * this.rowsPerPage + indexOnPage

      this.mapping.splice(index, 1)
      this.filterMapping()
    },
    categoryColor(category) {
      switch (category) {
        case 'ERM':
          return 'orange lighten-3'
        case 'ColorCode':
          return 'green lighten-3'
        case 'ColorDescription':
          return 'green lighten-5'
        case 'ProductGroup':
          return 'lime lighten-3'
        case 'BTEGroup':
          return 'lime lighten-3'
        case 'DTBGroup':
          return 'lime lighten-3'
        case 'FedasGroup':
          return 'lime lighten-3'
        case 'Collection':
          return 'light-blue lighten-3'
        case 'Size':
          return 'cyan lighten-3'
        case 'SubSize':
          return 'cyan lighten-5'
        case 'GenderSupplier':
          return 'pink lighten-5'
      }
      return 'white'
    },
    goToLastRow() {
      this.$nextTick(() => {
        const element = document.getElementById('propertyMappingTable')
        element.lastChild?.scrollIntoView()
      })
    },
    goToLastPage() {
      this.currentPage = this.pageCount
    },
  },
}
</script>

<style>
.left .v-text-field__slot input {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
}
</style>
