<template>
  <div class="d-flex justify-center mt-2">
    <div :style="$vuetify.breakpoint.mdAndUp ? 'max-width:820px' : ''" class="px-4">
      <v-card-title class="pl-0" style="word-break: break-word;">
        {{ swT('filter-skus') }}
      </v-card-title>
      <v-row class="d-flex">
        <v-col v-for="filter of filterTerms" :key="filter.field" cols="12">
          <span v-if="filter.field">
            <v-autocomplete
              v-model="filter.selected"
              :data-test="filter.value"
              clearable
              dense
              outlined
              hide-details
              hide-selected
              multiple
              small-chips
              deletable-chips
              :items="getItems(filter.field)"
              :label="swT(fieldTranslationKey(filter.field))"
            >
              <template v-slot:prepend-inner>
                <v-icon :color="filter.negate ? 'error' : 'primary'" style="cursor:pointer" @click="filter.negate = !filter.negate">
                  {{ filter.negate ? 'mdi-not-equal-variant' : 'mdi-equal' }}
                </v-icon>
              </template>
            </v-autocomplete>
          </span>

          <span v-else>
            <v-select v-model="filter.field" data-test="selectFilterField" dense clearable outlined hide-details :items="filterTermChoices" :label="swT('select_field')"></v-select>
          </span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { swT } from '@/functions/i18n'
import skuEditorFunctions from '../functions/skuEditorFunctions'
export default {
  props: ['value'],
  data() {
    return {
      swT,
      filterTerms: [
        { field: 'collection', selected: [], negate: false },
        { field: 'brand', selected: [], negate: false },
        { field: 'articleGroup', selected: [], negate: false },
      ],
    }
  },
  computed: {
    filterTermChoices() {
      const fields = ['articleCodeSupplier', 'articleCode', 'articleDescription', 'size', 'colorFamily', 'quickPick']
      this.$store.state.activeConfig.products.activeAttributes.value.forEach((a) => fields.push('_' + a))
      return fields.map((f) => ({ value: f, text: swT(this.fieldTranslationKey(f)) })).filter((f) => !this.filterTerms.find((t) => t.field == f.value))
    },
  },
  watch: {
    filterTerms: {
      handler() {
        this.$emit('sku-editor-prefiltered', this.filterTerms)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    addFilter() {
      this.filterTerms.push({ field: '', selected: [], negate: false, beta: true })
    },
    fieldTranslationKey(field) {
      if (field.substring(0, 1) == '_') return field.substring(1)
      return 'sku.' + field
    },
    getItems(field) {
      return ['( Blanks )'].concat(skuEditorFunctions.getItems(field))
    },
  },
}
</script>
