import { render, staticRenderFns } from "./swBrandCard.vue?vue&type=template&id=23146f52&scoped=true"
import script from "./swBrandCard.vue?vue&type=script&lang=js"
export * from "./swBrandCard.vue?vue&type=script&lang=js"
import style0 from "./swBrandCard.vue?vue&type=style&index=0&id=23146f52&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23146f52",
  null
  
)

export default component.exports