<template>
  <v-container v-hotkey="keymap" class="pa-0 ma-0">
    <div v-if="$store.state.skuLoading">
      <v-skeleton-loader tile type="table" max-width="600" class="mx-auto"></v-skeleton-loader>
    </div>
    <div v-else>
      <v-toolbar>
        <!-- Toolbar  => wizard filter -->
        <div v-if="!displayReportFilterToolbar && !calculatingIndicator" class="full-width d-flex justify-space-between align-center">
          <swBreadCrumbs :breadcrumbs="breadcrumbs" />
          <v-spacer></v-spacer>

          <!-- Table -->
          <v-btn
            v-if="$store.state.reportViz == 'table' && !$store.state.reportsFilterFullUI"
            class="mx-2"
            color="info"
            small
            data-test="switchToResults"
            @click="
              $store.state.reportsFilterFullUI = true
              toggleFoldUI(false)
            "
          >
            <v-icon>mdi-flag-checkered</v-icon>
          </v-btn>
          <v-btn
            v-if="$store.state.reportViz == 'table' && !$store.state.reportsFilterFullUI && !$vuetify.breakpoint.smAndDown"
            data-test="switchToFullUI"
            color="info"
            small
            @click="
              $store.state.reportsFilterFullUI = true
              toggleFoldUI(true)
            "
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
          <v-btn
            v-if="$store.state.reportViz == 'table' && $store.state.reportsFilterFullUI && $vuetify.breakpoint.smAndDown"
            small
            hide-details
            :label="swT('fullUI')"
            color="info"
            data-test="goToWizard"
            class="mx-1"
            @click.stop="
              $store.state.reportsFilterFullUI = !$store.state.reportsFilterFullUI
              localFilterStepperModel = 0
            "
          >
            <v-icon>mdi-wizard-hat</v-icon>
          </v-btn>

          <!-- Stock -->

          <v-btn-toggle v-if="$store.state.reportViz == 'matrix'" v-model="matrixVizibilityToggles" background-color="white" multiple dense>
            <v-btn data-test="hideZeroes" value="hideZeroes" :active="true" @click="hideZeroes = !hideZeroes">
              <v-icon>mdi-numeric-0</v-icon>
            </v-btn>

            <v-btn data-test="showImage" value="showImage" @click="showImage = !showImage">
              <v-icon>mdi-image</v-icon>
            </v-btn>

            <v-btn
              data-test="hideFullGrouper"
              value="hideFullGrouper"
              :active="true"
              @click="$store.state.articleStatus.hideFullGrouper = !$store.state.articleStatus.hideFullGrouper"
            >
              <v-icon>{{ $store.state.articleStatus.hideFullGrouper ? 'mdi-format-letter-case-upper' : 'mdi-format-letter-case-lower' }}</v-icon>
            </v-btn>

            <v-btn data-test="showPositiveValues" value="showPositiveValues" @click="showPositiveValues = !showPositiveValues">
              <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn data-test="showNegativeValues" value="showNegativeValues" @click="showNegativeValues = !showNegativeValues">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>

        <!-- Toolbar => full UI filter -->
        <!-- 
                We explicitly do not use the v-progress-linear components' reactiveness 
                on the value property because it will try to animate. On fast machines, 
                the updates happen so fast, they interfere with the animation. 
          -->
        <v-toolbar-items v-if="calculatingIndicator">
          <br />
          <div class="d-flex align-center py-2 my-1" :style="'top:0;position:absolute;width:' + parseInt((98 * calculationJob) / nrCalculationJobs) + '%'">
            <v-progress-linear height="40" striped rounded buffer-value="0" value="100"></v-progress-linear>
          </div>
        </v-toolbar-items>
        <div v-show="displayReportFilterToolbar && !calculatingIndicator" style="width:100%">
          <keep-alive>
            <swReportFilterToolbar
              :consistency-checked="consistencyChecked"
              :dates="dates"
              :commands="commands"
              @copyDeeplink="copyDeeplink"
              @updateUISettings="updateUISettings($event)"
              @updateDates="updateDates($event)"
              @run-command="runCommand"
            />
          </keep-alive>
        </div>
      </v-toolbar>

      <!-- Wizard -->
      <div v-if="!$store.state.reportsFilterFullUI && $store.state.reportViz == 'table'">
        <v-card class="mx-4">
          <swReportFilterWizard :any-date="anyDate" :dates="dates" @updateUISettings="updateUISettings($event)" @updateDates="updateDates($event)" />
        </v-card>
      </div>

      <!-- Full UI Filters -->
      <div v-if="$vuetify.breakpoint.smAndUp && $store.state.reportsFilterFullUI && $store.state.foldReportsUI" flat class="mx-3 my-1 no-print">
        <div class="d-flex flex-column">
          <v-card outlined elevation="3" class="mb-1">
            <v-card-text class="pa-2">
              <v-btn-toggle v-model="toggleRelativePeriods" dense class="tgl-group" color="info">
                <v-btn
                  v-for="relativeDay in relativePeriods"
                  :key="relativeDay"
                  class="ellipsis"
                  small
                  :data-test="`relativeDay-${relativeDay}`"
                  @click="updateDates({ period: '' + relativeDay })"
                >
                  {{ relativeDay }}
                </v-btn>
              </v-btn-toggle>

              <div class="py-1 d-flex justify-space-between">
                <v-btn-toggle v-model="toggleYears" dense class="pr-1 half-tgl-group tgl-group" color="info">
                  <v-btn v-for="year in $store.getters.years" :key="year" small class="ellipsis" :data-test="year" @click="updateDates({ wholeYear: '' + year })">
                    {{ year }}
                  </v-btn>
                </v-btn-toggle>

                <v-btn-toggle v-model="toggleQuarters" dense class="half-tgl-group tgl-group" color="info">
                  <v-btn v-for="quarter in [1, 2, 3, 4]" :key="'Q' + quarter" small @click="updateDates({ quarter: 'Q' + quarter, year: dates[0].slice(0, 4) })">
                    Q{{ quarter }}
                  </v-btn>
                </v-btn-toggle>
              </div>

              <v-btn-toggle v-model="toggleMonths" dense class="tgl-group" color="info">
                <v-btn v-for="month in $store.getters.allMonthsOfTheYear" :key="month.monthNumber" small @click="updateDates({ year: dates[0].slice(0, 4), ...month })">
                  {{ month.monthName }}
                </v-btn>
              </v-btn-toggle>
            </v-card-text>
          </v-card>
          <v-card outlined elevation="3" class="mb-1">
            <v-card-text class="pa-2">
              <!-- Filters -->
              <v-row class="mb-n6 pb-0">
                <v-col class="text-center">
                  <v-autocomplete
                    v-model="$store.state.articleStatus.warehouseFilter"
                    outlined
                    hide-details
                    :items="getItems('warehouse')"
                    :label="swT('selectwarehouses')"
                    multiple
                    small-chips
                    deletable-chips
                    clearable
                    dense
                    data-test="warehouseFilter"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon
                        :color="$store.state.articleStatus.warehouseFilterEquals ? 'primary' : 'error'"
                        style="cursor:pointer"
                        @click="$store.state.articleStatus.warehouseFilterEquals = !$store.state.articleStatus.warehouseFilterEquals"
                      >
                        {{ $store.state.articleStatus.warehouseFilterEquals ? 'mdi-equal' : 'mdi-not-equal-variant' }}
                      </v-icon>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class="text-center">
                  <v-autocomplete
                    v-model="$store.state.articleStatus.brandFilter"
                    outlined
                    hide-details
                    :items="getItems('brand')"
                    :label="swT('selectbrands')"
                    multiple
                    small-chips
                    deletable-chips
                    clearable
                    dense
                  >
                    <template v-slot:prepend-inner>
                      <v-icon
                        :color="$store.state.articleStatus.brandFilterEquals ? 'primary' : 'error'"
                        style="cursor:pointer"
                        @click="$store.state.articleStatus.brandFilterEquals = !$store.state.articleStatus.brandFilterEquals"
                      >
                        {{ $store.state.articleStatus.brandFilterEquals ? 'mdi-equal' : 'mdi-not-equal-variant' }}
                      </v-icon>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class="text-center">
                  <v-autocomplete
                    v-model="$store.state.articleStatus.collectionFilter"
                    outlined
                    hide-details
                    :items="getItems('skuCollection')"
                    :label="swT('selectcollections')"
                    multiple
                    small-chips
                    deletable-chips
                    clearable
                    dense
                  >
                    <template v-slot:prepend-inner>
                      <v-icon
                        :color="$store.state.articleStatus.collectionFilterEquals ? 'primary' : 'error'"
                        style="cursor:pointer"
                        @click="$store.state.articleStatus.collectionFilterEquals = !$store.state.articleStatus.collectionFilterEquals"
                      >
                        {{ $store.state.articleStatus.collectionFilterEquals ? 'mdi-equal' : 'mdi-not-equal-variant' }}
                      </v-icon>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class="text-center">
                  <v-autocomplete
                    v-model="$store.state.articleStatus.productGroupFilter"
                    outlined
                    hide-details
                    :items="getItems('articleGroup')"
                    :label="swT('selectproductgroups')"
                    multiple
                    small-chips
                    deletable-chips
                    clearable
                    dense
                  >
                    <template v-slot:prepend-inner>
                      <v-icon
                        :color="$store.state.articleStatus.productGroupFilterEquals ? 'primary' : 'error'"
                        style="cursor:pointer"
                        @click="$store.state.articleStatus.productGroupFilterEquals = !$store.state.articleStatus.productGroupFilterEquals"
                      >
                        {{ $store.state.articleStatus.productGroupFilterEquals ? 'mdi-equal' : 'mdi-not-equal-variant' }}
                      </v-icon>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <!-- Linking -->
              <v-row v-if="advancedUI || manualFilter" class="mb-n6">
                <v-col class="text-center">
                  <v-text-field
                    v-model="manualFilter"
                    data-test="reportFilter"
                    outlined
                    class="pb-3"
                    hide-details
                    clearable
                    :label="swT('filter')"
                    dense
                    prepend-inner-icon="mdi-filter"
                  ></v-text-field>
                  <!-- TODO LAT-3082 -->
                  <v-text-field
                    v-if="$store.state.reportViz == 'table'"
                    :value="deeplink"
                    data-test="reportDeeplink"
                    outlined
                    hide-details
                    :label="swT('deeplink')"
                    readonly
                    dense
                    prepend-inner-icon="mdi-link-variant"
                    append-icon="mdi-content-copy"
                    @click:append="copyDeeplink"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- Groupers and columns -->
              <v-row>
                <v-col class="text-center" cols="12" sm="6">
                  <div v-if="$store.state.reportViz != 'stockinsight'" data-cy="selectedGroups">
                    <v-combobox
                      v-model="$store.state.articleStatus.selectedGroups"
                      :items="$store.getters.translatedReportGroupColumns"
                      outlined
                      auto-select-first
                      hide-selected
                      hide-details
                      multiple
                      small-chips
                      deletable-chips
                      clearable
                      dense
                      data-test="selectedGroups"
                      prepend-inner-icon="mdi-file-tree"
                      :label="swT('selectgroups')"
                      @change="manageWithSubtotals($store.state.articleStatus.selectedGroups.length)"
                    >
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" label small>
                          <span class="pr-2" :data-test="`groupChip-${item.text}`">{{ swT(item.text) }}</span>
                          <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <span :data-test="`${item.text}`">{{ swT(item.text) }}</span>
                      </template>
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ swT('noData') }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </div>
                  <div v-else>
                    <v-combobox
                      v-model="stockInsightGroupers"
                      :items="$store.getters.translatedReportGroupColumns"
                      outlined
                      auto-select-first
                      hide-selected
                      hide-details
                      multiple
                      small-chips
                      deletable-chips
                      clearable
                      dense
                      data-test="selectedGroups"
                      prepend-inner-icon="mdi-file-tree"
                      append-icon="mdi-restore"
                      :label="swT('selectgroups')"
                      @click:append="resetGroups"
                      @change="manageWithSubtotals($store.state.articleStatus.selectedGroups.length)"
                    >
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" label small>
                          <span class="pr-2" :data-test="`groupChip-${item.text}`">{{ swT(item.text) }}</span>
                          <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <span :data-test="`${item.text}`">{{ swT(item.text) }}</span>
                      </template>
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ swT('noData') }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </div>
                </v-col>
                <v-col v-if="$store.state.reportViz !== 'matrix'" class="text-center" cols="12" sm="6">
                  <div data-cy="selectedFields">
                    <v-combobox
                      v-model="$store.state.articleStatus.selectedFields"
                      :items="$store.getters.translatedReportColumns"
                      outlined
                      hide-details
                      hide-selected
                      auto-select-first
                      multiple
                      data-test="selectedFields"
                      clearable
                      dense
                      :label="swT('selectfields')"
                      prepend-inner-icon="mdi-table-column"
                    >
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" label small>
                          <span class="pr-2" data-cy="columnChip" :data-test="`columnChip-${item.text}`">{{ swT(item.text) }}</span>
                          <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <span :data-test="`${item.text}`">{{ swT(item.text) }}</span>
                      </template>
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ swT('noData') }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </div>
                </v-col>

                <v-col v-if="$store.state.reportViz == 'matrix'" cols="12" sm="6" class="d-flex justify-end align-center">
                  <v-btn-toggle v-model="matrixVizibilityToggles" background-color="white" multiple dense>
                    <v-btn data-test="showImage" value="showImage" @click="showImage = !showImage">
                      <v-icon>mdi-image</v-icon>
                    </v-btn>

                    <v-btn data-test="showPositiveValues" value="showPositiveValues" @click="showPositiveValues = !showPositiveValues">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>

                    <v-btn data-test="showNegativeValues" value="showNegativeValues" @click="showNegativeValues = !showNegativeValues">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>

                    <v-btn data-test="hideZeroes" value="hideZeroes" :active="true" @click="hideZeroes = !hideZeroes">
                      <v-icon>mdi-numeric-0</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row v-if="$store.state.reportViz === 'stockinsight'">
                <v-col class="pt-0">
                  <v-combobox
                    v-model="$store.state.articleStatus.selectedKPIs"
                    :items="$store.getters.translatedReportColumns"
                    outlined
                    hide-details
                    hide-selected
                    auto-select-first
                    multiple
                    data-test="selectedKPIs"
                    clearable
                    dense
                    :label="swT('KPI')"
                    prepend-inner-icon="mdi-table-column"
                  >
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip v-bind="attrs" :input-value="selected" label small>
                        <span class="pr-2" data-cy="columnChip" :data-test="`columnChip-${item.text}`">{{ swT(item.text) }}</span>
                        <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <span :data-test="`${item.text}`">{{ swT(item.text) }}</span>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ swT('noData') }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col class="pt-0">
                  <v-autocomplete
                    v-model="stockInsightTableKey"
                    :items="$store.getters.translatedReportGroupColumns"
                    return-object
                    outlined
                    hide-details
                    auto-select-first
                    data-test="tableKey"
                    dense
                    :label="swT('tableKey')"
                    prepend-inner-icon="mdi-table-column"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </div>

      <!-- Data -->
      <swArticleStatusVisualizations
        v-if="Object.keys(rawAggregations).length < maxRows"
        v-show="$store.state.reportsFilterFullUI"
        :aggregations="rawAggregations"
        :dates="dates"
        :items-per-page="$store.state.articleStatus.itemsPerPage"
        :selected-groups="$store.state.articleStatus.selectedGroups"
        :table-sort-by="tableSortBy"
        :table-sort-desc="tableSortDesc"
        :with-subtotals="$store.state.articleStatus.withSubtotals"
        :hide-zeroes="!hideZeroes"
        :show-image="showImage"
        :show-positive-values="showPositiveValues"
        :show-negative-values="showNegativeValues"
        :group-choices="$store.getters.translatedReportGroupColumns"
        @setManualFilter="setFilter"
        @update:sort-by="tableSortBy = $event"
        @update:sort-desc="tableSortDesc = $event"
      />
      <div v-if="Object.keys(rawAggregations).length >= maxRows" class="pt-8">
        <v-row justify="center" align="center">
          <v-card min-width="320px" max-width="480px" min-height="240px" class="pa-4" elevation="8">
            {{ swT('tooManyResults') }}
          </v-card>
        </v-row>
      </div>
    </div>
  </v-container>
</template>
<script>
import { swT } from '@/functions/i18n'
import { calculateDateRange } from '@/functions/dateRange'
import globalStore from '../store/globalStore'
import articleStatusConfig from '../functions/articleStatusConfig'
import { articleStatus } from '@softwear/latestcollectioncore'
import reportVisualizationFunctions from '../functions/reportVisualizationFunctions'
import { deepCopy } from '@softwear/latestcollectioncore'
import { format, parse } from 'date-fns'
import startupDB from '../store/client'
import swArticleStatusVisualizations from '../components/swArticleStatusVisualizations.vue'
import swReportFilterWizard from '../components/swReportFilterWizard.vue'
import swReportFilterToolbar from '../components/swReportFilterToolbar.vue'
import { eventBus } from '../main'
import { v4 as uuidv4 } from 'uuid'
import print from '../functions/print'
import tools from '../functions/tools'
import webServices from '@/functions/webServicesFacade'
import { markRaw } from 'vue'
import swBreadCrumbs from '../components/swBreadCrumbs.vue'
import userFunctions from '../functions/users'
import consts from '@/store/consts'
import { showDialog } from '../functions/dialogService'

const transactionsCache = globalStore.getTransactionsCache()
export default {
  name: 'Reports',
  components: { swReportFilterToolbar, swReportFilterWizard, swArticleStatusVisualizations, swBreadCrumbs },
  props: ['query'],
  data() {
    return articleStatusConfig
  },
  computed: {
    stockInsightGroupers: {
      get() {
        return this.$store.state.articleStatus.selectedGroups.slice(0, -1)
      },
      set(value) {
        this.$store.state.articleStatus.selectedGroups = value.concat(this.stockInsightTableKey)
        return value
      },
    },
    displayReportFilterToolbar() {
      return this.$vuetify.breakpoint.smAndUp && this.$store.state.reportsFilterFullUI
    },
    showCommands() {
      return this.$store.state.reportsFilterFullUI && this.$store.state.foldReportsUI && this.$vuetify.breakpoint.mdAndUp && this.$route.query.type != 'stock'
    },
    allMonthsOfTheYear() {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
      // return an array of objects with the month name and the month number
      //  { monthName: 'jan', monthNumber: 1 }

      const months = []
      for (let i = 0; i < 12; i++) {
        const monthName = new Intl.DateTimeFormat(this.$store.state.locale, { month: 'short' }).format(new Date(2020, i, 1))
        months.push({ monthName, monthNumber: i })
      }
      return months
    },
    breadcrumbs() {
      if (this.$store.state.reportViz === 'table') {
        return [
          {
            text: this.swT('articlestatus'),
            disabled: false,
            to: consts.defaultURLs.ARTICLE_STATUS_LINK,
          },
        ]
      } else if (this.$store.state.reportViz === 'matrix') {
        return [
          {
            text: swT('stock_report'),
            disabled: false,
            to: consts.defaultURLs.STOCK_LINK,
          },
        ]
      } else {
        return []
      }
    },
    selectedGroupsValues: {
      get() {
        return this.$store.state.articleStatus.selectedGroups.map((group) => group.value)
      },
      set(value) {
        return value
      },
    },
    commands() {
      if (!this.$store.state.reportsFilterFullUI) return []
      return this.allCommands.filter((command) => !command.showOnlyWhen || command.showOnlyWhen(this))
    },
    keymap() {
      return {
        'ctrl+1': this.toggleFoldUI,
        'ctrl+-': this.toggleAdvancedUI,
      }
    },
    computedFilter() {
      const andFilters = []
      if (this.manualFilter) andFilters.push(this.manualFilter)

      if (this.$store.state.articleStatus.warehouseFilter && this.$store.state.articleStatus.warehouseFilter.length > 0)
        andFilters.push(`wh.name ${this.$store.state.articleStatus.warehouseFilterEquals ? 'in' : 'not in'} ("${this.$store.state.articleStatus.warehouseFilter.join('","')}")`)

      if (this.$store.state.articleStatus.brandFilter && this.$store.state.articleStatus.brandFilter.length > 0)
        andFilters.push(`sku.brand ${this.$store.state.articleStatus.brandFilterEquals ? 'in' : 'not in'} ("${this.$store.state.articleStatus.brandFilter.join('","')}")`)

      if (this.$store.state.articleStatus.collectionFilter && this.$store.state.articleStatus.collectionFilter.length > 0)
        andFilters.push(
          `sku.collection ${this.$store.state.articleStatus.collectionFilterEquals ? 'in' : 'not in'} ("${this.$store.state.articleStatus.collectionFilter.join('","')}")`
        )

      if (this.$store.state.articleStatus.productGroupFilter && this.$store.state.articleStatus.productGroupFilter.length > 0)
        andFilters.push(
          `sku.articleGroup ${this.$store.state.articleStatus.productGroupFilterEquals ? 'in' : 'not in'} ("${this.$store.state.articleStatus.productGroupFilter.join('","')}")`
        )
      return andFilters.join(' and ')
    },
    deeplink() {
      const REPORT_ENGINE_VERSION = 2
      const fields = this.$store.state.articleStatus.selectedFields.map((field) => field.value)
      const groups = this.$store.state.articleStatus.selectedGroups.map((group) => group.value)
      let URL = `reports?type=${this.query.type}&reportViz=${this.query.reportViz}&filter=${this.manualFilter || ''}&version=${REPORT_ENGINE_VERSION}`

      if (this.query.reportViz === 'stockinsight') {
        groups.splice(groups.length - 1, 1)
        const kpis = this.$store.state.articleStatus.selectedKPIs.map((kpi) => kpi.value)
        URL += `&kpis=${kpis}`
      }
      URL += `&groups=${groups}&fields=${fields}`

      // SORT AND ORDER BY
      const orderBy = this.tableSortBy.join(',')

      if (orderBy) URL += `&orderby=${orderBy}`

      const sortOrder = this.tableSortDesc
        .join(',')
        .replaceAll('false', '1')
        .replaceAll('true', '-1')

      if (sortOrder) URL += `&sortorder=${sortOrder}`

      // MAIN FILTERS
      const { warehouseFilter, brandFilter, collectionFilter, productGroupFilter } = this.$store.state.articleStatus
      const { warehouseFilterEquals, brandFilterEquals, collectionFilterEquals, productGroupFilterEquals } = this.$store.state.articleStatus

      // will add keys only if conditions are met, preventing unnecessary huge URLs
      const mainFilters = {
        ...(warehouseFilter != null && warehouseFilter.length > 0 && { warehouseFilter }),
        ...(brandFilter != null && brandFilter.length > 0 && { brandFilter }),
        ...(collectionFilter != null && collectionFilter.length > 0 && { collectionFilter }),
        ...(productGroupFilter != null && productGroupFilter.length > 0 && { productGroupFilter }),

        ...(!warehouseFilterEquals && { warehouseFilterEquals }),
        ...(!brandFilterEquals && { brandFilterEquals }),
        ...(!collectionFilterEquals && { collectionFilterEquals }),
        ...(!productGroupFilterEquals && { productGroupFilterEquals }),

        ...(this.query.reportViz === 'stockinsight' && { tablekey: this.stockInsightTableKey.value }),
      }
      URL += `&${new URLSearchParams(mainFilters).toString()}`

      // DATES
      if (this.selectedRelativePeriod.name === 'custom') URL += `&startdate=${this.dates[0]}&enddate=${this.dates[1]}`
      // month selected
      else if (this.selectedRelativePeriod.month >= 0) {
        URL += `&monthNumber=${this.selectedRelativePeriod.month}&year=${this.dates[0].slice(0, 4)}`
      }

      // quarter selected
      else if (typeof this.selectedRelativePeriod.name === 'string' && this.selectedRelativePeriod.name.startsWith('Q')) {
        URL += `&quarter=${this.selectedRelativePeriod.name.slice(0, 2)}&year=${this.dates[0].slice(0, 4)}`
      }

      // year selected (weakly determined by the length of the string)
      else if (typeof this.selectedRelativePeriod.name === 'string' && this.selectedRelativePeriod.name.length === 4) {
        URL += `&wholeYear=${this.dates[0].slice(0, 4)}`
      }

      // any other relative date selected
      else {
        URL += `&period=${this.selectedRelativePeriod.name}`
      }

      // SUBTOTALS
      // A missing showSubtotals parameter will be interpreted as true, as it is the default
      if (!this.$store.state.articleStatus.showSubtotals) URL += `&showSubtotals=false`

      const { href } = this.$router.resolve(URL)
      return window.location.host + href
    },
  },
  watch: {
    query: {
      handler(newQuery) {
        const store = this.$store.state.articleStatus
        // will skip the wizard on non default queries
        if (JSON.stringify(newQuery) !== JSON.stringify(store.defaultQuery)) this.$store.state.reportsFilterFullUI = true
        else this.$store.state.reportsFilterFullUI = false

        this.updateDates(newQuery)

        const { warehouseFilter, brandFilter, collectionFilter, productGroupFilter } = newQuery
        const { warehouseFilterEquals, brandFilterEquals, collectionFilterEquals, productGroupFilterEquals } = newQuery
        const reportEngineVersion = parseInt(newQuery?.version || '1')
        store.warehouseFilter = warehouseFilter ? warehouseFilter.split(',') : []
        store.brandFilter = brandFilter ? brandFilter.split(',') : []
        store.collectionFilter = collectionFilter ? collectionFilter.split(',') : []
        store.productGroupFilter = productGroupFilter ? productGroupFilter.split(',') : []

        store.warehouseFilterEquals = !warehouseFilterEquals
        store.brandFilterEquals = !brandFilterEquals
        store.collectionFilterEquals = !collectionFilterEquals
        store.productGroupFilterEquals = !productGroupFilterEquals

        this.manualFilter = newQuery?.filter || ''

        this.$store.state.reportViz = newQuery?.reportViz || 'table'

        store.selectedGroups = newQuery?.groups
          ? newQuery.groups.split(',').map((v) => this.groupChoices.find((g) => g.value == v))
          : [this.groupChoices.find((g) => g.value == 'transaction.ean')]

        // we make sure the stock insight table key is always the last element in the selected groups
        if (newQuery?.tablekey) {
          const tableKey = this.groupChoices.find((g) => g.value == newQuery.tablekey)

          store.selectedGroups.push(tableKey)
          this.stockInsightTableKey = tableKey
        }

        if (reportEngineVersion == 1) {
          // In version 1, we worked with 25 cell vectors. From version 2, we work with 40 cell vectors. Derived fields (those beyond the original base vector, > 24) are now shifted 15 places
          store.selectedFields = newQuery?.fields
            ? newQuery.fields
                .split(',')
                .map((v) => parseInt(v))
                .map((v) => this.fieldChoices.find((f) => f.value == (v > 24 ? v + 18 : v)))
            : []
          store.selectedKPIs = newQuery?.kpis
            ? newQuery.kpis
                .split(',')
                .map((v) => parseInt(v))
                .map((v) => this.fieldChoices.find((f) => f.value == (v > 24 ? v + 18 : v)))
            : []
        } else {
          store.selectedFields = newQuery?.fields ? newQuery.fields.split(',').map((v) => this.fieldChoices.find((f) => f.value == v)) : []
          store.selectedKPIs = newQuery?.kpis ? newQuery.kpis.split(',').map((v) => this.fieldChoices.find((f) => f.value == v)) : []
        }
        this.tableSortBy = newQuery.orderby?.split(',') || ['g0']
        this.tableSortDesc = newQuery.sortorder?.split(',').map((e) => e == '-1') || []

        store.showSubtotals = newQuery.showSubtotals === 'true' || newQuery.showSubtotals == undefined
      },
      deep: true,
      immediate: true,
    },
    stockInsightTableKey: {
      handler(newVal) {
        if (this.$store.state.articleStatus.selectedGroups.length > 0) {
          this.$store.state.articleStatus.selectedGroups = this.$store.state.articleStatus.selectedGroups.slice(0, -1).concat(newVal)
        }
      },
    },
  },
  async created() {
    this.$store.state.mainFilterPrompt = swT('fuzzy_search')
    this.$store.state.reportViz = this.query.reportViz || 'table'
    if (this.$vuetify.breakpoint.xs) this.$store.state.articleStatus.itemsPerPage = 15
    await this.$store.state.skuPromise
    await this.consistencyCheck()
    this.buildFieldIndex()
    this.setWatchers()
    this.manageWithSubtotals(this.$store.state.articleStatus.selectedGroups.length)
    await this.runQueryAndAggregate()
    if (this.$store.state.reportViz !== 'table') this.$store.state.reportsFilterFullUI = true
  },
  destroyed() {
    this.$store.state.paginationTotalPages = -1
  },
  methods: {
    resetGroups() {
      this.stockInsightGroupers = this.query?.groups
        ? this.query.groups.split(',').map((v) => this.groupChoices.find((g) => g.value == v))
        : [this.groupChoices.find((g) => g.value == 'transaction.ean')]
    },
    // Allows updating any data programmatically from other components
    // Should be used only for UI settings
    updateUISettings(config) {
      Object.keys(config).forEach((key) => {
        this[key] = config[key]
      })
    },
    async consistencyCheck() {
      // First check if the consistency check was already done today
      this.consistencyChecked = null
      const reportConsistencyChecked = localStorage.getItem('reportConsistencyChecked')
      const now = new Date()
      const today = format(now, 'yyyy-MM-dd')
      if (!this.$store.getters.uploadDirectory) return
      if (reportConsistencyChecked == today || this.$store.state.user.tenant?.salesMonitor) {
        this.consistencyChecked = today
        return
      }
      const tenant = this.$store.state.user

      try {
        // load most recent shard to perform consistency check
        const tenantId = this.$store.state.user.current_tenant
        const now = new Date()
        const shard = this.$store.state.activeConfig.reports.biGranularity.value == 'year' ? format(now, 'yyyy') : format(now, 'yyyyMM')
        await this.loadShard(tenantId, shard, shard)
        const stockSnapshot = await webServices.getStockSnapshot(this.$store.getters.uploadDirectory)
        const snapshotTimeStamp = parse(stockSnapshot.timestamp, 'yyyyMMddHHmmss', new Date()).getTime()

        const mostRecentTransaction = transactionsCache[shard].reduce((agg, row) => (agg < row.time ? row.time : agg), 0)
        if (mostRecentTransaction < snapshotTimeStamp) return

        const inventory = transactionsCache[shard].reduce((agg, row) => {
          const { ean, wh, type, time, vector } = row
          if (!agg[ean]) agg[ean] = {}
          if (!agg[ean][wh]) agg[ean][wh] = 0
          if (['0', '1', '2', '3', '4', '5', '6', '7', '9'].includes(type) && time < snapshotTimeStamp) agg[ean][wh] += vector[1]
          return agg
        }, {})
        // compare stockSnapshot with inventory in transactions
        const inventoryIdentical = !stockSnapshot.items.find(
          ({ barcode, wh, qty }) => inventory[barcode] === undefined || inventory[barcode][wh] === undefined || inventory[barcode][wh] != qty
        )
        let fullMatch = 0
        const qtyMismatch = []
        const whMismatch = []
        const noMatch = []
        stockSnapshot.items.forEach(({ barcode, wh, qty }) => {
          if (inventory[barcode] !== undefined && inventory[barcode][wh] !== undefined && inventory[barcode][wh] == qty) fullMatch = fullMatch + 1
          else if (inventory[barcode] !== undefined && inventory[barcode][wh] !== undefined && inventory[barcode][wh] != qty) qtyMismatch.push(barcode)
          else if (inventory[barcode] !== undefined && inventory[barcode][wh] == undefined) whMismatch.push(barcode)
          else noMatch.push(barcode)
        })
        if (inventoryIdentical) {
          this.consistencyChecked = format(snapshotTimeStamp, 'yy-MM-dd HH:mm')
          localStorage.setItem('reportConsistencyChecked', today)
          return
        }
        // transactiondata does not match inventory snapshot.
        // this can indicate that the transactiondata is incomplete, send a message to the datateam to invesstigate
        webServices.sendToOutbox({
          sender: tenantId,
          receiver: 'LatestCollection',
          type: 'transactionDataIncomplete',
          body: {
            fullMatch: fullMatch,
            qtyMismatch: qtyMismatch.slice(0, 10),
            whMismatch: whMismatch.slice(0, 10),
            noMatch: noMatch.slice(0, 10),
          },
        })
        if ((qtyMismatch.length + whMismatch.length + noMatch.length) / fullMatch < 0.01) {
          this.consistencyChecked = format(snapshotTimeStamp, 'yy-MM-dd HH:mm')
          localStorage.setItem('reportConsistencyChecked', today)
        }
      } catch (e) {
        // probably couldn't load the check file
        // don't bother, it can wait
        webServices.sendToOutbox({
          sender: tenant.current_tenant,
          receiver: 'LatestCollection',
          type: 'transactionDataIncomplete',
          body: {
            tenant: tenant,
            error: e,
          },
        })
      }
    },
    copyToExcel() {
      eventBus.$emit('copyToExcel')
    },
    showCommand(command) {
      if (command.role && !userFunctions.hasRole(this.$store.getters.roles, command.role)) return false

      if (!command.showOnlyWhen) return true
      const fn = command.showOnlyWhen
      return fn()
    },
    async runCommandWithFieldSelection(e) {
      await this.runCommand(e.context, e.value)
    },
    async createCommandEvent(command, selectedFields) {
      if (this.$store.state.articleStatus.selectedFields.length == 1) selectedFields = this.$store.state.articleStatus.selectedFields
      if (selectedFields !== undefined && !Array.isArray(selectedFields)) selectedFields = [selectedFields]
      if (command.selectFields != 'no' && selectedFields == undefined) {
        let valuePickDialogItems = []
        if (command.fieldsToPickFrom) valuePickDialogItems = command.fieldsToPickFrom.map((f) => ({ text: swT(this.fieldChoicesIndex[f]), value: f }))
        else valuePickDialogItems = this.$store.state.articleStatus.selectedFields.map((f) => ({ text: swT(this.fieldChoicesIndex[f]), value: f }))
        const answer = await showDialog('swValuePickDialog', {
          multiple: command.selectFields == 'multiple',
          context: command,
          title: command.command,
          items: valuePickDialogItems,
          message: command.message,
        })
        if (answer) this.runCommandWithFieldSelection(answer)
        return
      }
      const eventObject = {
        type: 'command',
        id: uuidv4(),
        payload: {
          command: command.command,
          endpoint: command.endpoint || command.command,
          context: {
            warehouses: this.$store.state.articleStatus.warehouseFilter,
            brands: this.$store.state.articleStatus.brandFilter,
            collections: this.$store.state.articleStatus.collectionFilter,
            productGroups: this.$store.state.articleStatus.productGroupFilter,
            beginDate: this.dates[0],
            endDate: this.dates[1],
            reportViz: this.query.reportViz,
            selectedGroups: this.selectedGroupsValues,
            selectedFields: selectedFields?.map((f) => ({ field: this.fieldChoicesIndex[f], index: f })) || [],
          },
        },
      }
      // Get postAggFilters from table reportViz
      let postAggFilters = {}
      eventBus.$emit('getPostAggFilters', {
        callbackFn: (filters) => {
          postAggFilters = filters
        },
      })
      // We should only pass those barcodes in the eventObject that satisfy the post-aggregation filters (if any are set in articleStatusTableViz)

      await this.runQueryGroupedByBarcode()
      const justOneField = selectedFields?.length == 1
      eventObject.payload.rows = Object.entries(this.rawAggregations)
        .filter((oneAggregatedRow) => {
          const expression = oneAggregatedRow[0]
          const originalGroups = expression.substring(expression.indexOf('\t') + 1)
          return postAggFilters[originalGroups]
        })
        .map((oneAggregatedRow) => {
          const expression = oneAggregatedRow[0]
          const key = expression.substring(0, expression.indexOf('\t'))
          const vector = [].slice.call(oneAggregatedRow[1]) // vector is now a normal Array instead of a Float64Array so can be extended with additional fields
          articleStatus.postAgg(vector, articleStatus.getDateRangeFromPicker(this.dates).timeFrame)
          const returnValue = { id: key }
          if (justOneField) returnValue.qty = vector[selectedFields[0]]
          else
            selectedFields?.forEach((field) => {
              returnValue[this.fieldChoicesIndex[field]] = vector[field]
            })
          return returnValue
        })
      eventObject.fromRoute = this.deeplink
      return eventObject
    },
    async runCommand(command, selectedFields) {
      const eventObject = await this.createCommandEvent(command, selectedFields)
      if (!eventObject) return
      if (this.$store.state.rowsInfo.filtered > 5000) {
        this.$store.dispatch('raiseAlert', {
          header: 'tooManyResults',
          type: 'error',
          body: 'applyMoreRestrictions',
          timeout: 5000,
        })
        return
      }
      await this.$store.dispatch('ensureTenantCollection', 'eventlog')
      await this.$store.dispatch('updateObjects', {
        api: globalStore.getLatestCollectionAPI('eventlog'),
        data: [eventObject],
      })

      const localCommands = {
        PriceTags: this.PriceTags,
      }
      const fn = localCommands[command.command]
      if (fn) await fn(eventObject)
      else
        this.$router.push({
          name: command.endpoint || command.command,
          query: { eventLog: eventObject.id, from: eventObject.fromRoute.substring(eventObject.fromRoute.indexOf('?') + 1) },
        })
    },
    async PriceTags(eventObject) {
      // setup a printBuffer array with one object per barcode-label
      // Each object will contain all the properties needed to generate one label

      const skus = globalStore.getLatestCollectionObject('sku')
      const printBuffer = []
      eventObject.payload.rows.forEach((item) => {
        const sku = skus[item.id]
        if (sku) printBuffer.push(print.sku4BarcodeLabel(sku, item.qty))
      })
      await showDialog('swPrintDialog', { printBuffer: printBuffer })
    },
    // The intention is to always calculate subtotals for the `table` reportViz, for 2 groupers and up
    // The user can only control whether to show them or not through the UI
    // If other reports will use subtotals, maybe we can rename the store module `articleStatus` to something more generic like `reports`
    manageWithSubtotals(value) {
      if (this.$store.state.reportViz == 'stockinsight') this.$store.state.articleStatus.withSubtotals = 'fullSingleTotals'
      if (value > 1 && this.$store.state.reportViz == 'table') this.$store.state.articleStatus.withSubtotals = true
      else this.$store.state.articleStatus.withSubtotals = false
    },
    setWatchers() {
      const run = this.runQueryAndAggregate
      this.$watch('dates', run)
      this.$watch('$store.state.articleStatus.withSubtotals', run)
      this.$watch('$store.state.articleStatus.warehouseFilter', run)
      this.$watch('$store.state.articleStatus.brandFilter', run)
      this.$watch('$store.state.articleStatus.collectionFilter', run)
      this.$watch('$store.state.articleStatus.productGroupFilter', run)
      this.$watch('manualFilter', run)
      this.$watch('$store.state.articleStatus.selectedGroups', run)
    },
    buildFieldIndex() {
      this.fieldChoicesIndex = this.fieldChoices.reduce((agg, el) => {
        agg[el.value] = deepCopy(el.text)
        return agg
      }, {})
    },
    copyDeeplink() {
      const link = process.env.NODE_ENV === 'development' ? `http://${this.deeplink}` : `https://${this.deeplink}`
      navigator.clipboard.writeText(link).then(() => {
        this.$store.dispatch('raiseAlert', {
          header: swT('copied_to_clipboard'),
          type: 'success',
          timeout: 5000,
        })
      })
    },
    toggleFoldUI(setFolded) {
      if (typeof setFolded === 'boolean') this.$store.state.foldReportsUI = setFolded
      // here we catch keyboard event as well
      else this.$store.state.foldReportsUI = !this.$store.state.foldReportsUI
      this.$forceUpdate()
    },
    toggleAdvancedUI() {
      this.advancedUI = !this.advancedUI
    },
    // This function catches all calls for date updates
    // Should stop support for strings in the future, move to objects
    // SelectedRelativePeriod is used to help build the deeplink
    // Allows for language independent deeplinks
    updateDates(dateRange) {
      // used only for UI reasons, not for the actual query
      this.anyDate = dateRange.period || dateRange

      if (typeof dateRange === 'object') {
        if (Object.prototype.hasOwnProperty.call(dateRange, 'monthNumber')) {
          this.selectedRelativePeriod.month = dateRange.monthNumber
          dateRange.monthName = this.$store.getters.allMonthsOfTheYear[dateRange.monthNumber].monthName
        } else this.selectedRelativePeriod = {}
      }

      let calculatedDates
      if (typeof dateRange === 'string') calculatedDates = calculateDateRange({ period: dateRange })
      if (typeof dateRange === 'object') calculatedDates = calculateDateRange(dateRange)

      this.dates = calculatedDates.dates
      this.selectedRelativePeriod.name = calculatedDates.name
      this.$store.state.articleStatus.filterDateRange = calculatedDates

      this.$nextTick(() => {
        this.toggleMonths = this.toggleQuarters = this.toggleYears = this.toggleRelativePeriods = null
      })
    },
    setFilter(filter) {
      this.manualFilter = filter
    },
    async runQueryGroupedByBarcode() {
      const restoreInfo = {
        selectedGroups: this.$store.state.articleStatus.selectedGroups,
        reportViz: this.$store.state.reportViz,
      }
      this.$store.state.articleStatus.selectedGroups = [
        { text: 'transaction.ean', value: 'transaction.ean', reportViz: 'table' },
        ...this.$store.state.articleStatus.selectedGroups,
      ]
      this.$store.state.reportViz = 'table'
      await this.runQueryAndAggregate()
      this.$store.state.articleStatus.selectedGroups = restoreInfo.selectedGroups
      this.$store.state.reportViz = restoreInfo.reportViz
    },
    calculateFIFOInventoryValue(transactions) {
      const receivingHistory = {} // Keeps a list of [qty,price] pairs per barcode
      // Construct receiving history data
      for (const transaction of transactions) {
        const barcode = transaction.ean
        const vector = transaction.vector
        if (!receivingHistory[barcode]) receivingHistory[barcode] = []
        const history = receivingHistory[barcode]
        const historyLength = history.length
        const lastPrice = historyLength > 0 ? history[historyLength - 1][1] : 0
        const qty = vector[articleStatus.QTY_STOCK]
        if (transaction.type == 0 || transaction.type == 1) {
          // Beginstock && Receiving
          if (qty > 0) {
            const pricePerUnit = vector[articleStatus.AMOUNT_STOCK] / qty
            if (historyLength > 0 && lastPrice == pricePerUnit) history[historyLength - 1][0] += qty
            else history.push([qty, pricePerUnit])
          }
          continue
        }
        if (qty > 0) {
          // Return, transit, change
          if (historyLength > 0) history[historyLength - 1][0] += qty
          else history.push([qty, lastPrice])
        }
      }
      // Assign FIFO value to all outgoing transactions
      for (const transaction of transactions) {
        const barcode = transaction.ean
        const vector = transaction.vector
        const history = receivingHistory[barcode]
        if (history.length == 0) continue // Nothing we can assign to any outgoing transaction
        const qty = vector[articleStatus.QTY_STOCK]
        if (qty > 0) {
          // Beginstock && Receiving, Return, transit, change
          continue
        }
        // Simple, fast and most common scenario is that this transaction can be satisfied from the first batch in receivingHistory:
        const qtyOut = -qty
        if (qtyOut <= history[0][0]) {
          history[0][0] -= qtyOut
          const price = history[0][1]
          if (transaction.type == 2) vector[articleStatus.COSTPRICE_SOLD] = price * qtyOut
          if (transaction.type == 3) vector[articleStatus.AMOUNT_TRANSIT] = price * qtyOut
          if (transaction.type == 4) vector[articleStatus.AMOUNT_CHANGE] = price * qtyOut
          continue
        }
        // If transaction cannot be satisfied from oldest batch, calculate total transaction value from oldest batches
        let totalTransactionValue = 0
        let unsatisfiedQty = qtyOut
        let batchPointer = 0
        while (unsatisfiedQty > 0) {
          const available = history[batchPointer][0]
          if (available > 0) {
            if (available >= unsatisfiedQty) {
              history[batchPointer][0] -= unsatisfiedQty
              totalTransactionValue += unsatisfiedQty * history[batchPointer][1]
              unsatisfiedQty = 0
            } else {
              unsatisfiedQty -= available
              history[batchPointer][0] = 0
              totalTransactionValue += available * history[batchPointer][1]
            }
          }
          batchPointer++
          if (batchPointer >= history.length) unsatisfiedQty = 0
        }
        const price = totalTransactionValue / qtyOut
        if (transaction.type == 2) vector[articleStatus.COSTPRICE_SOLD] = price * qtyOut
        if (transaction.type == 3) vector[articleStatus.AMOUNT_TRANSIT] = price * qtyOut
        if (transaction.type == 4) vector[articleStatus.AMOUNT_CHANGE] = price * qtyOut
      }
    },
    async loadShard(tenantId, lastShard, shard) {
      try {
        if (this.$route.query.feature == 'shadowtransaction')
          transactionsCache[shard] = (await startupDB({ url: '/' + tenantId + '/shadowtransaction/' + shard, immutable: shard != lastShard })).read()
        else transactionsCache[shard] = (await startupDB({ url: '/' + tenantId + '/transaction/' + shard, immutable: shard != lastShard })).read()
        // transactionsCache[shard] = transactionsCache[shard].filter((t) => t.ean == '4065931837053')
        if (this.$store.state.activeConfig.reports.articlestatus_fifo.value) {
          console.time('sort')
          // FIFO calculation requires all transactions to be sorted on time
          transactionsCache[shard].sort((t1, t2) => t1.time - t2.time)
          console.timeEnd('sort')
          // While we're testing this, make sure that costprice columns are set to 0 as not to rely on old data
          // When the calculations aredeemed correct, this can be removed.
          // By that time, the legacy code that produces the transaction records do not have to populate these fields anymore for sales,transits and changes, only for receivings
          transactionsCache[shard].forEach((t) => {
            if (t.type == 2) t.vector[articleStatus.COSTPRICE_SOLD] = 0
            if (t.type == 3) t.vector[articleStatus.AMOUNT_TRANSIT] = 0
            if (t.type == 4) t.vector[articleStatus.AMOUNT_CHANGE] = 0
          })
          console.time('calculate')
          this.calculateFIFOInventoryValue(transactionsCache[shard])
          console.timeEnd('calculate')
        }
        return true
      } catch (e) {
        console.log(e)
        this.calculatingIndicator = false
        this.$store.dispatch('raiseAlert', {
          header: 'nodataforshard',
          type: 'warning',
          body: shard,
          timeout: 5000,
        })
        return false
      }
    },
    async runQueryPeriodRange(dateRanges, parentGroupTotals) {
      const dateRange = articleStatus.getDateRangeFromDatePair(dateRanges[0])

      await this.runQueryAndAggregatePeriod(dateRange, parentGroupTotals)
      this.rawAggregations = this.rawAggregationBuffer

      eventBus.$emit('updateVisualizations', this.rawAggregationBuffer)
      this.$forceUpdate()
      dateRanges = dateRanges.slice(1)
      if (dateRanges.length > 0) {
        await this.runQueryPeriodRange(dateRanges, parentGroupTotals)
        this.rawAggregations = this.rawAggregationBuffer
      }
    },

    /**
     * runQueryAndAggregate
     *     runQueryPeriodRange
     *        runQueryAndAggregatePeriod
     *            runQuery
     */
    async runQueryAndAggregate() {
      const timeGrouper = articleStatus.getTimeGrouper(this.selectedGroupsValues)
      this.rawAggregationBuffer = {}
      let dateRanges = []
      if (timeGrouper)
        dateRanges = tools
          .generateTimeframes(new Date(this.dates[0]), new Date(this.dates[1]), timeGrouper)
          .map((timeFrame) => [timeFrame[0].toLocaleDateString('sv-SE'), timeFrame[1].toLocaleDateString('sv-SE')])
      // .toLocaleDateString('sv-SE') transtaltes to yyyy-mm-dd which is sort-friendly
      else dateRanges = [this.dates]

      this.calculatingIndicator = true
      this.calculationJob = 0
      this.nrCalculationJobs = dateRanges.length + 1
      await this.runQueryPeriodRange(dateRanges, false)
      if (this.$store.state.reportViz == 'stockinsight') {
        await this.runQueryPeriodRange([this.dates], true)
        this.rawAggregations = this.rawAggregationBuffer
        eventBus.$emit('updateVisualizations', this.rawAggregationBuffer)
        this.$forceUpdate()
      }
      this.calculationJob = this.nrCalculationJobs
      setTimeout(() => {
        this.calculatingIndicator = false
      }, 500)
    },
    async runQueryAndAggregatePeriod(dateRange, parentGroupTotals) {
      const aggregate = {}
      this.shardsToProcess = articleStatus.whichShardsToProcess(dateRange, this.$store.state.activeConfig.reports.biGranularity.value)
      this.nrCalculationJobs += this.shardsToProcess.length - 1
      const lastShard = this.shardsToProcess.slice(-1)
      this.shardProcessing = 1
      const tenantId = this.$store.state.user.current_tenant
      for (const shard of this.shardsToProcess) {
        this.shardProcessing = this.shardsToProcess.indexOf(shard) * 2
        this.calculationJob++
        if (!transactionsCache[shard] && !(await this.loadShard(tenantId, lastShard, shard))) continue

        const effectiveGroups = reportVisualizationFunctions.prepareGroupsForVisualization(this.$store.state.reportViz, this.selectedGroupsValues)
        // Allow the UI to update in between shards.
        let customers = {}
        if (this.$store.state.reportViz == 'customertable') {
          if (globalStore.getLatestCollectionArray('consumer')?.length == 0) globalStore.setLatestCollectionAPI('consumer', await startupDB({ url: '/' + tenantId + '/consumer' }))
          this.$store.state.totalNrConsumers = globalStore.getLatestCollectionArray('consumer')?.length

          customers = globalStore.getLatestCollectionObject('consumer')
          if (this.$store.state.activeConfig.salesChannels.mySalesChannels.value.includes('wholesale')) {
            if (globalStore.getLatestCollectionArray('customer')?.length == 0)
              globalStore.setLatestCollectionAPI('customer', await startupDB({ url: '/' + tenantId + '/customer' }))
            globalStore.getLatestCollectionArray('customer').forEach((customer) => (customers[customer.id] = customer))
          }
        }
        await new Promise((resolve) =>
          setImmediate(async () => {
            this.shardProcessing = this.shardsToProcess.indexOf(shard) * 2 + 1
            const result = markRaw(
              articleStatus.runQuery(
                dateRange,
                aggregate,
                shard == this.shardsToProcess[0],
                transactionsCache[shard],
                effectiveGroups,
                globalStore.getLatestCollectionObject('sku'),
                globalStore.getLatestCollectionObject('warehouse'),
                customers,
                this.$store.state.articleStatus.withSubtotals,
                this.computedFilter,
                parentGroupTotals
              )
            )
            Object.entries(result).forEach((entry) => {
              this.rawAggregationBuffer[entry[0]] = entry[1]
            })

            // if (shard == lastShard) {
            //   this.calculatingIndicator = false
            // }
            if (!this.rawAggregations) return
            const shardsInCache = Object.keys(transactionsCache)
            const nrRecordsInCache = shardsInCache.reduce((total, key) => total + transactionsCache[key].length, 0)
            // In Firefox, we can use all the memory we need.
            // In other browsers, we cap the memory usage at 3M objects to stay below 4GB
            if (tools.detectBrowser() != 'firefox' && nrRecordsInCache > 10 * 1000 * 1000) delete transactionsCache[shardsInCache[0]]
            resolve()
          })
        )
      }
    },
    getItems(field) {
      return globalStore.getItems(field, this.$store)
    },
  },
}
</script>
<style scoped>
.v-input--is-readonly {
  pointer-events: auto;
}
.tgl-group {
  display: flex;
  flex-wrap: wrap;
}
.half-tgl-group {
  width: 50%;
}
.tgl-group > button {
  flex: auto;
}
span .v-chip {
  margin-top: 3px !important;
}
.full-width {
  width: 100%;
}
</style>
