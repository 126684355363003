import { SkuI, ProductI, AttributeI, vatCategoryE, MarkedSkuI, MatrixI, RowI, ColorI, BrandSettingI } from '@softwear/latestcollectioncore'
import globalStore from '../store/globalStore'
import tools from '../functions/tools'
import userFunctions from '../functions/users'
import { hashBrand, deepCopy, sizeToMap } from '@softwear/latestcollectioncore'
import { markRaw } from 'vue'

function isBrandEqual(hashedBrand: string, collection: string, aliases: string[]) {
  return hashedBrand == collection || aliases?.includes(hashedBrand)
}
function isSkuOfBrand(sku: SkuI, collection: string, aliases: string[]) {
  const hashedBrand = sku.BRANDHASH || hashBrand(sku.brand)
  return isBrandEqual(hashedBrand, collection, aliases)
}
function getBrandSettings(brandOrHash: string, brands: BrandSettingI[]): BrandSettingI {
  const hashedBrand = hashBrand(brandOrHash)
  return brands.find((brand) => isBrandEqual(hashedBrand, brand.collection, brand.aliases))
}

function indexKey(color: string, subColor: string, size: string): string {
  return color + '\t' + (subColor || '') + '\t' + size
}

/**
 * Return all the different attribute names and values from the skus array
 * This list is usefull in UI's where a user needs to pick an existing attribute name.
 *
 * @private
 * @param {Array<SkuI>} skus Array of sku objects
 */
function getAttributes(skus: Array<SkuI>): Array<AttributeI> {
  const attributeNames: Array<string> = []
  skus.forEach(function(sku: SkuI) {
    Object.keys(sku).forEach((k) => {
      if (k[0] == '_' && k[1] != '_') {
        const attributeName = k.substring(1)
        if (attributeName != null && attributeName != undefined && attributeNames.indexOf(attributeName) == -1) attributeNames.push(attributeName)
      }
    })
  })
  const attributes = {}
  attributeNames.forEach((attribute) => {
    attributes[attribute] = []
  })
  skus.forEach(function(sku: SkuI) {
    Object.keys(sku).forEach((k) => {
      if (k[0] == '_' && k[1] != '_') {
        const attributeName = k.substring(1)
        if (sku[k] != null && sku[k] != undefined && attributes[attributeName].indexOf(sku[k]) == -1) attributes[attributeName].push(sku[k])
      }
    })
  })

  const attributesArray: Array<unknown> = []
  for (const attribute in attributes) {
    if (Object.prototype.hasOwnProperty.call(attributes, attribute)) {
      attributesArray.push({ key: attribute, value: attributes[attribute] })
    }
  }
  attributesArray.sort((a: any, b: any) => (a.key < b.key ? -1 : 1))

  const sortedAttributes = {} as Array<AttributeI>
  attributesArray.forEach((e: any) => (sortedAttributes[e.key] = e.value.sort()))
  return sortedAttributes
}

const buildSizeIndex = (sizeOrderingData, mapSubSizes: boolean) => (sku) => {
  // Determine horizontal sizeIndex so we can display sizes in a matrix.
  // The sizeIndex is a 5 digit string. sizeIndex + size is used to order sizes.
  // For numerical sizes, sizeIndex is a leftpadded string of the size itself.
  // For other sizes, we lookup the size in the sizeOrdering map. If it exists, it's sizeIndex is listed there
  // along with optional size/subsize splitting values.
  // If we can't find it, we look wether there is a map rule for the longest prefix of our size.
  // When found, that sizeIndex will be used but not the splitting data. This works well with polutted sizes
  // like XS**** etc...
  if (!sku.size) return
  const rawSize = sizeToMap(sku.size)
  let prefix = rawSize
  let mappedIndex = null
  do {
    mappedIndex = sizeOrderingData[prefix]
    if (mappedIndex != undefined) {
      sku.sizeIndex = mappedIndex.order || '00000'
      const sizeFound = mapSubSizes && prefix == rawSize && mappedIndex.size
      if (sizeFound) {
        sku.mainSize = mappedIndex.size
        if (mappedIndex.subSize) {
          sku.subSize = mappedIndex.subSize
          sku.size = mappedIndex.size + mappedIndex.subSize
        } else {
          sku.size = mappedIndex.size
        }
      } else {
        sku.mainSize = rawSize
      }
      break
    }
    prefix = prefix.slice(0, -1)
  } while (prefix)
  if (!mappedIndex) {
    if (!sku.size) {
      sku.mainSize = rawSize
      sku.size = rawSize
    }
    if (!sku.mainSize) sku.mainSize = sku.size
    if (!isNaN(parseInt(prefix))) sku.sizeIndex = tools.padLeft(rawSize, 5, '0')
  }
}

function latestCollectionBrandSkusNotInTenant(latestCollectionBrandSkus: MarkedSkuI[], allSkusObject: object): MarkedSkuI[] | SkuI[] {
  // Return latestCollection skus that the tenant doesn't have

  const lcSkus = latestCollectionBrandSkus.filter((latestCollectionSku: SkuI) => !allSkusObject[latestCollectionSku.barcode || latestCollectionSku.id])
  // There is no harm in reusing the objects in the brand collection for the tenant's own copy since the tenant cannot change the brand collection
  // In case of a sowftwear-admin, it\s the other way around.
  lcSkus.forEach((sku: any) => {
    sku.barcode = sku.id
    delete sku.id
    sku.active = true
    sku.articleCodeSupplier = sku.articleCodeSupplier || sku.articleCode
    sku.source = 'latestCollection'
    sku.mainColorCode = ''
    sku.mainColorDescription = ''
    sku.skuActive = true
    sku.colorSupplier = sku.colorSupplier || sku.colorFamily
  })
  return lcSkus
}

function sortSkusByCollection(skus) {
  const collectionOrder = function(o: SkuI) {
    const year = o.collection?.substring(0, 2) || '00'
    if (year > '50') return '' + parseInt(year)
    return '' + (99 - parseInt(year))
  }
  const sortExpression = function(o: SkuI) {
    return (o.images ? '0' : '1') + collectionOrder(o) + o.articleCode + '.' + o.colorFamily + '.' + o.sizeIndex + '.' + o.size + '.' + o.subColorCode
  }
  skus.sort((a: SkuI, b: SkuI) => {
    return sortExpression(a) < sortExpression(b) ? -1 : 1
  })
  return skus
}

const articleGrouperExpression = function(sku: SkuI): string {
  return sku.articleCodeSupplier
}
/**
 * Return all products from a given brand from the global skus.
 *
 * Group sku's into product objects.
 * Add all sku's of a product to its SKUS array.
 * Add all sizes of a product to its SIZES array.
 * Add all colors of a product to its COLORS array.
 *
 * @private
 * @param {string} brand brand is used as a filter on the global skus array
 */
function getBrandProducts(brandSkus: MarkedSkuI[], sizeOrdering, mapSubSizes: boolean): ProductI[] {
  if (brandSkus.length == 0) return []
  brandSkus.forEach((sku: SkuI) => buildSizeIndex(sizeOrdering, mapSubSizes)(sku))
  // Order the skus by articleCode/color/sizeIndex
  // Is this sorting needed?
  brandSkus = sortSkusByCollection(brandSkus)

  // Get 'products' from skus by selecting the unique values at the articleCode property
  const t = {}
  const uniqueBrandProducts = brandSkus.filter((e: SkuI) => {
    const key = articleGrouperExpression(e)
    return !(t[key] = key in t)
  })

  // Make a deep copy so we can safely change the 'products'
  // (which are in essence just one of the skus's of a product)
  const brandProducts = deepCopy(uniqueBrandProducts)

  // Add sku's to the product they belong
  let currentArticleGrouperValue, currentProduct
  for (const sku of brandSkus) {
    const articleGrouperValue = articleGrouperExpression(sku)
    if (currentArticleGrouperValue != articleGrouperValue) {
      currentArticleGrouperValue = articleGrouperValue
      currentProduct = brandProducts.find((sku: SkuI) => articleGrouperExpression(sku) == currentArticleGrouperValue)
      if (!currentProduct.SKUS) currentProduct.SKUS = []
    }
    currentProduct.SKUS.push(sku)
    if (sku.SOURCE == 'latestCollection') currentProduct.source = 'latestCollection'
    if (sku.usedByTenant) currentProduct.usedByTenant = true
  }

  brandProducts.forEach(function(p: any) {
    p.IMAGES =
      p.images?.split(',').map((i) => {
        return { name: i, url: i }
      }) || []
    p.active = true
  })

  const typedBrandProducts = (brandProducts as unknown) as ProductI[]
  // Side effect
  globalStore.setBrandProducts(typedBrandProducts)

  return typedBrandProducts
}

/**
 * Return an empty color object
 *
 * @private
 */
function newColor() {
  return {
    color: '',
    subColors: [],
    IMAGES: [],
    colorCodeSupplier: '',
    colorSupplier: '',
    newColor: true,
  }
}

function processProduct(product, grouper = '') {
  const hashtable = {}
  // Loop through each SKU in the productSlug array
  product.SKUS.forEach((sku) => {
    // Create the unique identifier for grouping
    const key = grouper ? `${sku.colorFamily}__${sku.size}__${sku[grouper]}` : `${sku.colorFamily}__${sku.size}`

    // If the key doesn't exist in the hashtable, add it
    if (!hashtable[key]) {
      hashtable[key] = []
    }

    hashtable[key].push(sku)
  })
  // Add the duplicates object to the product
  // product.SKUS = xskus
  // @ts-ignore
  product.DUPLICATES = Object.fromEntries(Object.entries(hashtable).filter(([key, value]) => value.length > 1))

  return product
}
/**
 * Augment the given product object with details from the underlying skus.
 * The given product can be an existing product or one sku of a product.
 *
 * @param product
 */
function getProductDetails(product: ProductI, grouperName, grouperList = ['']): ProductI {
  if (product.ATTRIBUTES == undefined) {
    product.ATTRIBUTES = {}
    // Attributes, as all properties, are stored redundantly at the SKU level, however, we assume their values are the same on all sku's.
    // That's why, in order to collect them at the productlevel, we collect them from the first sku in our list
    if (product.SKUS.length > 0) {
      Object.keys(product.SKUS[0]).forEach((k) => {
        if (k[0] == '_' && k[1] != '_') product.ATTRIBUTES[k.substring(1)] = product.SKUS[0][k]
      })
    }
  }
  if (product.SKUS.length > 0 && product.pricetagLayouts) product.pricetagLayouts = product.SKUS[0].pricetagLayouts.split(',')
  if (product.SIZES == undefined) {
    const sizes = product.SKUS.map((e) => ({ size: e.mainSize, sizeIndex: e.sizeIndex }))
    sizes.sort((a, b) => (a.sizeIndex + a.size < b.sizeIndex + b.size ? -1 : 1))
    product.SIZES = []
    sizes.forEach((s) => {
      if (!product.SIZES.includes(s.size)) product.SIZES.push(s.size)
    })
  }
  if (product.COLORS == undefined) {
    //
    //  Collect all unique colors in this product.
    //  We use colorFamily as the key, if it's empty, use mainColorDescription instead
    //  Within each color, collect the corresponding subcolors (usually CUPs or LENGTHs)
    //  It's not sufficient to discriminate on the colorFamily since there might be multiple copies with identical
    //  values. When we detect that, we suffix the colorFamily with (xxx) as to make them unique
    //
    product.COLORS = []
    const colorObject = {}
    const hasSubSizes = product.SKUS.find((s) => s.subSize)
    if (hasSubSizes) {
      product.SKUS.forEach((s) => {
        if (!s.subSize) s.subSize = ' '
      })
    }
    for (const sku of product.SKUS) {
      const colorKey = sku.colorFamily
      if (!colorObject[colorKey]) {
        colorObject[colorKey] = {
          color: sku.colorFamily,
          subColors: [],
          IMAGES: sku.images?.split(',').map((image) => {
            return { name: image, url: image }
          }),
          SUBCOLORsizeSupplier_COMBIS: [],
          colorCodeSupplier: '',
          colorSupplier: '',
        }
      }
      if (!colorObject[colorKey].SUBCOLORsizeSupplier_COMBIS.includes(sku.subColorCode + '.' + sku.size)) {
        colorObject[colorKey].SUBCOLORsizeSupplier_COMBIS.push(sku.subColorCode + '.' + sku.size)
      }
      if (sku.subSize && colorObject[colorKey].subColors.indexOf(sku.subSize) == -1) {
        colorObject[colorKey].subColors.push(sku.subSize)
      }
      colorObject[colorKey].colorCodeSupplier = sku.colorCodeSupplier
      colorObject[colorKey].colorSupplier = sku.colorSupplier
    }

    processProduct(product, grouperName)

    for (const key in colorObject) {
      colorObject[key].subColors.sort()
      product.COLORS.push({
        color: colorObject[key].color,
        subColors: colorObject[key].subColors,
        IMAGES: colorObject[key].IMAGES,
        colorCodeSupplier: colorObject[key].colorCodeSupplier,
        colorSupplier: colorObject[key].colorSupplier,
      })
    }
  }
  if (product.INDEX == undefined) {
    // Build an index to find barcodes based on color/subSize/mainSize
    // We need that to quickly find barcodes while looping through sizes and color

    product.INDEX = {}
    product.SKUS.forEach((s) => {
      if (grouperList[0].length) {
        if (!s[grouperName]) s[grouperName] = ''
      }
      if (grouperName) product.INDEX[`${indexKey(s.colorFamily, s.subSize, s.mainSize)}\t${s[grouperName] || ''}`] = s.barcode
      else product.INDEX[indexKey(s.colorFamily, s.subSize, s.mainSize)] = s.barcode
    })
  }
  return product
}

function newSku(color = { colorCodeSupplier: '', colorSupplier: '', color: '' }, size = '', sizeIndex = '', subColor = ''): SkuI {
  // We don't add id and barcode, the server will do that for us
  return {
    barcode: '',
    articleCode: '',
    articleDescription: '',
    pricetagLayouts: '',
    brand: '',
    articleGroup: '',
    groups: [],
    vat: 0,
    vatCategory: vatCategoryE.HIGH,
    mainColorCode: '',
    price: 0,
    buyPrice: 0,
    suggestedRetailPrice: 0,
    salePrice: 0,
    collection: '',
    active: true,
    skuActive: true,
    warehouseLocation: '',
    colorCodeSupplier: color.colorCodeSupplier,
    colorSupplier: color.colorSupplier,
    colorFamily: color.color,
    primary: true,
    size: size,
    mainSize: size,
    // @ts-ignore
    sizeIndex: sizeIndex,
    subColorDescription: subColor,
    subColorCode: subColor,
    subSize: subColor,
  }
}

/**
 * Returns an array of brand objects combining all the LatestCollection brands and the tenant-defined brands that are unknown to LatestCollection.
 * Apply tenant-specific settings.
 */
function combineBrands(state: any, skus: Array<any>) {
  const tenant = state.user.current_tenant
  const isDataOnly = userFunctions.hasRole(state.user.roles[tenant], 'lc-data-only')

  const allBrands = globalStore
    .getLatestCollectionArray('metabrandsetting')
    .filter((e: any) => {
      //   return true
      if (!isDataOnly) return true
      return e.gln && e.gln != '' && e.gln.length == 13
    })
    .map((e: any) => ({ source: 'latestCollection', usedByTenant: false, ...e }))
  const tenantBrands = tools.getUniqueValues(skus.map((e) => e.brand || 'unknown'))
  for (const brand of tenantBrands) {
    const brandHash = hashBrand(brand)
    const lcBrand = allBrands.find((brand) => isBrandEqual(brandHash, brand.collection, brand.aliases))
    if (lcBrand == undefined) {
      allBrands.push({
        name: brand,
        id: brand,
        collection: brandHash,
        source: 'tenant',
        usedByTenant: true,
      })
    } else {
      lcBrand.usedByTenant = true
      lcBrand.hideBrand = false
    }
  }
  if (userFunctions.hasRole(state.user.roles[tenant], 'products-dataprovider_admin')) {
    // For dataProviderAdmin's: apply dataProviderBrandSettings
    globalStore.getLatestCollectionArray('dataproviderbrandsetting').forEach((brandSetting) => {
      const brand = allBrands.find(({ id }) => id == brandSetting.id.split(':')[0])
      if (brand) {
        brand.dataProvider = brandSetting.dataProvider
        brand.propertyMapping = deepCopy(brandSetting.propertyMapping)
      }
    })
  } else if (userFunctions.hasRole(state.user.roles[tenant], 'sw')) {
    // Apply meta brand settings
    globalStore.getLatestCollectionArray('metabrandsetting').forEach((brandSetting) => {
      const brand = allBrands.find(({ id }) => id == brandSetting.id)
      if (brand) {
        brand.propertyMapping = deepCopy(brandSetting.propertyMapping)
      }
    })
  } else {
    // Apply tenant defined brand settings to our brands array
    globalStore.getLatestCollectionArray('tenantbrandsetting').forEach((brandSetting) => {
      const brand = allBrands.find(({ id }) => id == brandSetting.id)
      if (brand) {
        brand.discount = brandSetting.discount
        brand.calculation = brandSetting.calculation
        brand.orderMethod = brandSetting.orderMethod
        brand.lastSync = brandSetting.lastSync || 0
        brand.lastAutomatedSync = brandSetting.lastAutomatedSync || 0
        brand.propertyMapping = deepCopy(brandSetting.propertyMapping)
        brand.orderMethodConfiguration = deepCopy(brandSetting.orderMethodConfiguration)
        brand.footprint = brandSetting.footprint
        brand.freeShippingThreshold = brandSetting.freeShippingThreshold
      }
    })
  }
  allBrands.sort((a, b) => {
    return (a.source == 'latestCollection' ? '0' : '1') + (a.usedByTenant ? '0' : '1') + a.name <
      (b.source == 'latestCollection' ? '0' : '1') + (b.usedByTenant ? '0' : '1') + b.name
      ? -1
      : 1
  })

  // filter out hidden brand
  if (tenant != 'master' || !userFunctions.hasRole(state.user.roles[tenant], 'sw')) {
    return allBrands.filter((brand) => brand.hideBrand == false || brand.hideBrand == undefined)
  } else {
    return allBrands
  }
}

function buildProductIndexedObject(skus) {
  return skus.reduce((products, sku) => {
    products[`${sku.brand}__${sku.articleCodeSupplier}`] = (products[`${sku.brand}__${sku.articleCodeSupplier}`] || []).concat(markRaw(sku))
    return products
  }, {})
}

/**
 * Return all skus from a given brand from the tenant skus + brand skus.
 * This function started out using as series of .map. It generated so many new objects that memory consumption and GC started to play up real bad.
 * The .forEach replacements speeds stuff up x2
 * Additionally, we use Vue's MarkRaw here to prevent these sku's to become reactive which would grind things to a halt on large arrays
 **/
function getBrandSkus(brandSetting: BrandSettingI): MarkedSkuI[] | SkuI[] {
  const latestCollectionBrandSkus = globalStore.getLatestCollectionObject('selectedbrand')
  const allSkus = markRaw([])
  globalStore
    .getLatestCollectionArray('sku')
    .filter((sku: SkuI) => isSkuOfBrand(sku, brandSetting?.collection, brandSetting?.aliases) || latestCollectionBrandSkus[sku.barcode || sku.id])
    .forEach((sku) => {
      sku.source = latestCollectionBrandSkus[sku.barcode || sku.id] ? 'latestCollection' : 'tenant'
      sku.barcode = sku.id
      sku.usedByTenant = true
      allSkus.push(markRaw(sku))
    })
  latestCollectionBrandSkusNotInTenant(globalStore.getLatestCollectionArray('selectedbrand'), globalStore.getLatestCollectionObject('sku')).forEach((sku) =>
    allSkus.push(markRaw(sku))
  )
  allSkus.forEach((sku: SkuI) => {
    sku.price = typeof sku.price == 'string' ? parseFloat(sku.price) : sku.price
    sku.buyPrice = typeof sku.buyPrice == 'string' ? parseFloat(sku.buyPrice) : sku.buyPrice
    sku.skuActive = sku.skuActive === undefined ? true : sku.skuActive
  })
  return allSkus
}
async function setBrandContext(store, brand = '', dataProvider = undefined, cachedBrand) {
  // We cannot afford to load the brand data if it's already loaded, it will remove all the barcodes from globalStore
  if (cachedBrand === undefined) throw new Error('cachedBrand is required')
  if (hashBrand(brand) === hashBrand(cachedBrand)) return false
  const hashedBrand = hashBrand(brand)
  const brandSetting = getBrandSettings(hashedBrand, store.state.brands)
  const newData = await store.dispatch('loadBrand', { brand: brandSetting?.collection, dataProvider })
  const skus = getBrandSkus(brandSetting)

  globalStore.setBrandSkus(skus)
  if (!newData) return false
  globalStore.setIndexedBrandProducts(buildProductIndexedObject(skus))
  return true
}
const createCell = (items, skus) => {
  return {
    items: items,
    value: items.reduce((sum, item) => sum + item[1], 0),
    key: items.map((item) => item[0]).join(','),
    skus,
  }
}

/**
 * Returns `RowI`.
 *
 * Creates a new matrix row.
 * @private
 * @param {string[]} arr: contains the color and subcolor values. color + subColor is the default grouper for the matrix, always present.
 * @param {ProductI} product: the product for which we build the matrix.
 * @param {MarkedSkuI[]} inputBarcodes: collection of skus for which we want to build matrices. Here we update matrix quantities from inputBarcodes.
 */
function createRow(arr: string[], product: ProductI, inputBarcodes: MarkedSkuI[], grouperName, grouperList): RowI {
  // eslint-disable-next-line
  let [color, subColor, group] = arr
  const grouper = group ? `${color} ${subColor} / ${group}` : `${color} ${subColor}`
  const row = markRaw({ group: grouper, cells: [] })
  for (const size in product.SIZES) {
    const grouperKey = group ? `${color}__${product.SIZES[size]}${subColor}__${group}` : `${color}__${product.SIZES[size]}${subColor}`

    if (!grouperName && product.DUPLICATES[grouperKey]) {
      const duplicatesArr = product.DUPLICATES[grouperKey]
      const initialItems = duplicatesArr.map((sku) => [sku.barcode, sku.qty || 0])
      row.cells.push(createCell(initialItems, duplicatesArr))
      continue
    }

    if (grouperName && product.DUPLICATES[grouperKey]) {
      const duplicatesArr = product.DUPLICATES[grouperKey]
      const initialItems = duplicatesArr.map((sku) => [sku.barcode, sku.qty || 0])
      row.cells.push(createCell(initialItems, duplicatesArr))
      continue
    }

    const key = indexKey(color, subColor, product.SIZES[size])
    const barcode = product.INDEX[grouperName ? `${color}\t${subColor}\t${product.SIZES[size]}\t${group}` : key]
    if (grouperList[0].length) {
      row.cells.push({ key: barcode, value: inputBarcodes.find((sku) => sku.barcode === barcode && sku[grouperName] === group)?.qty || 0 })
    } else {
      row.cells.push({ key: barcode, value: inputBarcodes.find((sku) => sku.barcode === barcode)?.qty || 0 })
    }
  }
  return row
}

/**
 * Returns `ProductI`.
 *
 * Gets the whole skus objects.
 * @private
 * @param {MarkedSkuI[]} skus: can be all skus or brand specific skus. Taken from the `globalStore` We have only barcodes at this point and we need full sku data so we can later use `articleCode`s.
 * @param {MarkedSkuI[]} inputBarcodes: collection of skus for which we want to build matrices. Here we update matrix quantities from inputBarcodes.
 * @param {Record<string, any>} sizeOrdering: hardcoded size table used to interpret different size names. Static resource sent by backend.
 * @param {boolean} mapSubSizes: whether we should apply mapping from sizeOrdering object or not. Static resource sent by backend.
 */
function turnTargetSkusIntoProducts(skus: MarkedSkuI[], inputBarcodes: MarkedSkuI[], sizeOrdering: Record<string, any>, mapSubSizes: boolean, grouperName, grouperList): ProductI {
  let mergedSkus
  if (grouperList[0].length) {
    const skusGroupedByGrouperList = []

    skus.forEach((sku) => {
      grouperList.forEach((group) => {
        const newSku = { ...sku }
        newSku[grouperName] = group
        if (!newSku.barcode) newSku.barcode = newSku.id
        buildSizeIndex(sizeOrdering, mapSubSizes)(newSku)
        const inputBarcode = inputBarcodes.find((iSku) => newSku.barcode === iSku.barcode && newSku[grouperName] === iSku[grouperName])
        if (inputBarcode) {
          newSku.qty = inputBarcode.qty
        }
        skusGroupedByGrouperList.push(newSku)
      })
    })
    mergedSkus = skusGroupedByGrouperList
  } else {
    mergedSkus = skus?.map((sku: MarkedSkuI) => {
      if (!sku.barcode) sku.barcode = sku.id
      buildSizeIndex(sizeOrdering, mapSubSizes)(sku)
      const inputBarcode = inputBarcodes.find((iSku) => sku.barcode === iSku.barcode)
      return inputBarcode ? { ...sku, ...inputBarcode } : sku
    })
  }
  const product = markRaw({}) as ProductI
  product.SKUS = mergedSkus
  return getProductDetails(product, grouperName, grouperList)
}

function identifyUniqueGroupsInProductSkus(productSkus, grouper): string[] {
  const uniqueGroups = ['']
  productSkus.forEach((sku) => {
    if (!uniqueGroups.includes(sku[grouper])) uniqueGroups.push(sku[grouper])
  })
  return uniqueGroups
}

/**
 * Returns `ProductI`.
 *
 * Prepare the matrix and metadata and add it to the given product.
 * @private
 * @param {ProductI} product: the product that we need the matrix for.
 * @param {number} index: the index of the given product.
 * @param {MarkedSkuI[]} inputBarcodes: collection of barcode objects for which we want to build matrices.
 * @param {Function} matrixMetadata: callback function to extract product info
 * This info is used mainly to display information above the matrix.
 * For example the articleCode property can be displayed on top of the matrix as a unique identifier
 * for that matrix.
 */
function buildMatrixProduct(product: ProductI, index: number, inputBarcodes: MarkedSkuI[], matrixMetadata: Function, grouperName, grouperList): ProductI {
  const matrix = { headers: [], rows: [], matrixMetadata: '' } as MatrixI
  const grouperHeader = grouperName ? ` / ${grouperName}` : ''
  matrix.headers = [`color${grouperHeader}`].concat(
    product.SIZES.map((size: string) => size),
    ['total']
  )

  let uniqueGroups
  if (grouperName) uniqueGroups = grouperList[0].length ? grouperList : identifyUniqueGroupsInProductSkus(product.SKUS, grouperName)

  product.COLORS.forEach((color: ColorI) => {
    if (grouperName) {
      uniqueGroups.forEach((group) => {
        if (color.subColors.length == 0) {
          const r = createRow([color.color, '', group], product, inputBarcodes, grouperName, grouperList)
          // if all keys are undefined, we don't want to add the row
          if (r.cells.find((cell) => cell.key != undefined)) matrix.rows.push(r)
        } else {
          color.subColors.forEach((subColor: string) => {
            const r = createRow([color.color, subColor, group], product, inputBarcodes, grouperName, grouperList)
            if (r.cells.find((cell) => cell.key != undefined)) matrix.rows.push(r)
          })
        }
      })
    } else {
      if (color.subColors.length == 0) {
        matrix.rows.push(createRow([color.color, ''], product, inputBarcodes, grouperName, grouperList))
      } else {
        color.subColors.forEach((subColor: string) => {
          matrix.rows.push(createRow([color.color, subColor], product, inputBarcodes, grouperName, grouperList))
        })
      }
    }
  })

  // sort rows by first column
  matrix.rows.sort((a, b) => {
    return a.group < b.group ? -1 : 1
  })

  product.MATRIX = matrix
  product.MATRIX.matrixMetadata = matrixMetadata(product, index)
  return product
}

/**
 * Appends a `MATRIX` key containing matrix data to a product.
 *
 * @param {MarkedSkuI[]} inputBarcodes: Collection of barcode objects to build matrices for.
 * @param {SkuI[]} indexedSkus: SKUs to match against the inputBarcodes.
 * @param {ProductI[]} indexedProducts: Products to extract data for matrices. Typically, all tenant products or a subset (brand products).
 * @param {Function} matrixMetadata: Callback to extract product info for display above the matrix (e.g., articleCode as a unique identifier).
 * @param {Record<string, any>} sizeOrdering: Hardcoded size table for interpreting size names. Static resource from backend.
 * @param {boolean} mapSubSizes: Flag to apply mapping from sizeOrdering object. Static resource from backend.
 * @param {string} grouperName: This is an optional parameter used as a header for the first column.
 * If a list of groupers is provided, the function will group by each item in the list.
 * The grouperName should correspond to a SKU property. If it doesn't naturally exist as a SKU property like color or size,
 * the input SKUs need to be restructured to include the correct property.
 * For instance, to group by warehouse, the warehouse property must be incorporated into the input SKUs.
 * This parameter supports translation.
 * @param {string[]} grouperList: Optional. Groups rows by each item in the list. Requires a grouperName for the header. Used to limit the grouper to a list of values.
 * Acts as a filter for the grouperName.
 * @param {boolean} hideInactiveSkus: Flag to filter out inactive SKUs.
 * @return {ProductI[]} Products array now containing matrix data.
 */

function buildMatrices(
  inputBarcodes: MarkedSkuI[],
  indexedSkus: SkuI[],
  indexedProducts: ProductI[],
  matrixMetadata: Function,
  sizeOrdering: Record<string, any>,
  mapSubSizes: boolean,
  grouperName = '',
  grouperList = [''],
  hideInactiveSkus = false
): ProductI[] {
  const products = {} // skus grouped by product for which we will calculate the matrices
  // we select the products we need to show the user
  inputBarcodes.forEach((inputBarcode: MarkedSkuI): void => {
    const barcode: string = inputBarcode.barcode
    const sku: SkuI = indexedSkus[barcode]
    if (!sku) return
    const key = `${sku.brand}__${sku.articleCodeSupplier}`
    let skus: MarkedSkuI[] = deepCopy(indexedProducts[key])
    if (hideInactiveSkus) skus = skus.filter((sku: MarkedSkuI) => sku.active)
    if (!products[key]) products[key] = skus
  })
  // 'SKUS' key must match existing pipeline expectation
  // for each product we calculate the matrix data
  return (
    Object.values(products)
      // turn target skus into products
      .map(
        (skus: any): ProductI => {
          return turnTargetSkusIntoProducts(skus, inputBarcodes, sizeOrdering, mapSubSizes, grouperName, grouperList)
        }
      )
      .map(
        (product: ProductI, index: number): ProductI => {
          return buildMatrixProduct(product, index, inputBarcodes, matrixMetadata, grouperName, grouperList)
        }
      )
  )
}

function setAllMatrixQuantities(rows, quantity = 0) {
  return rows.map((row) => {
    row.cells.map((cell) => {
      cell.value = quantity
      return cell
    })
    return row
  })
}

function multiplyAllMatrixQuantities(rows, multiplier = -1) {
  return rows.map((row) => {
    row.cells.map((cell) => {
      if (cell.value != 0 && cell.value != undefined) cell.value = parseInt(cell.value) * multiplier
      return cell
    })
    return row
  })
}

const ensureImpliedProperties = function(sku: SkuI) {
  // Ensure mapping fields
  if (!sku.articleGroupSupplier) sku.articleGroupSupplier = sku.articleGroup
  if (!sku.collectionSupplier) sku.collectionSupplier = sku.collection
  if (!sku.colorCodeSupplier) sku.colorCodeSupplier = sku.colorCode
  if (!sku.sizeSupplier) sku.sizeSupplier = sku.size

  if (!sku.colorFamily && sku.colorSupplier) sku.colorFamily = '' + sku.colorSupplier
  if (!sku.colorFamily && sku.colorDescription) sku.colorFamily = '' + sku.colorDescription
  if (!sku.colorFamily && sku.mainColorDescription) sku.colorFamily = '' + sku.mainColorDescription
  if (!sku.articleCodeSupplier) sku.articleCodeSupplier = sku.articleCode
  if (!sku.size && sku.sizeSupplier) sku.size = sku.sizeSupplier
  if (!sku.barcode) sku.barcode = sku.id
}

function calculateMargin(sku: SkuI, vatHi: number): string {
  if (!sku.price || sku.price == 0) return (0.0).toFixed(1) + '%'

  let vat = vatHi
  if (sku.vat) vat = sku.vat

  const price = (sku.price / (100 + vat)) * 100
  if (sku.buyPrice) return (((price - sku.buyPrice) / price) * 100).toFixed(1) + '%'
}

function prepareSkusForUI(skus: SkuI[], state) {
  const tenantBrandsObject = globalStore.getLatestCollectionObject('tenantbrandsetting')
  const brandsObject = globalStore.getLatestCollectionObject('metabrandsetting')
  const hashCache = {}
  const addBRANDHASH = function(sku) {
    // Map brand
    if (!hashCache[sku.brand]) hashCache[sku.brand] = hashBrand(sku.brand)
    const hashedBrand = hashCache[sku.brand]
    const brand = brandsObject[hashedBrand]
    if (brand) {
      sku.brand = brand.name
      sku.BRANDHASH = brand.id
      return
    }
    const brandSettings = getBrandSettings(hashedBrand, state.brands)
    if (brandSettings) {
      sku.brand = brandSettings.name
      sku.BRANDHASH = brandSettings.id
      return
    }
    brandsObject[hashedBrand] = { id: hashedBrand, name: sku.brand }
    sku.BRANDHASH = hashedBrand
  }
  const addFOOTPRINT = function(sku) {
    if (state.activeConfig.reports.articlestatus_footprint.value) {
      const tenantBrand = tenantBrandsObject[sku.BRANDHASH]
      // @ts-ignore
      if (tenantBrand) sku.FOOTPRINT = tenantBrand.footprint
      // @ts-ignore
      else sku.FOOTPRINT = 1
    }
  }
  skus.forEach(addBRANDHASH)
  skus.forEach(addFOOTPRINT)
  skus.forEach(state.propertyMappingFn)
  skus.forEach(ensureImpliedProperties)
  skus.forEach(buildSizeIndex(state.metadata.sizeOrdering.data, state.activeConfig.products.mapSubSizes.value))
}

export default {
  buildMatrices,
  buildProductIndexedObject,
  buildSizeIndex,
  calculateMargin,
  combineBrands,
  ensureImpliedProperties,
  getAttributes,
  getBrandProducts,
  getBrandSettings,
  getBrandSkus,
  getProductDetails,
  indexKey,
  isSkuOfBrand,
  multiplyAllMatrixQuantities,
  newColor,
  newSku,
  prepareSkusForUI,
  setAllMatrixQuantities,
  setBrandContext,
}
