<template>
  <div>
    <v-card class="mb-2" elevation="5">
      <v-row class="ma-0">
        <v-col>
          <v-select v-model="selectedWarehouses" multiple :items="getItems('warehouseId')" small-chips deletable-chips hide-details dense outlined></v-select>
        </v-col>
        <v-col>
          <v-select v-model="selectedProperty" data-test="selectProperty" :items="properties" hide-details dense outlined></v-select>
        </v-col>
      </v-row>
    </v-card>
    <swMatrix :headers="headers" :rows="inventory" :show-eye="true" :fixed-header="true" :header-offset="220" @update="updateCell" />
  </div>
</template>

<script>
import { swT } from '@/functions/i18n'
import globalStore from '@/store/globalStore'
import productFunctions from '../functions/products'
import { eventBus } from '../main'
import swMatrix from '../components/swMatrix.vue'
import { deepCopy } from '@softwear/latestcollectioncore'

export default {
  components: {
    swMatrix,
  },
  props: ['product'],
  data() {
    return {
      swT,
      selectedProperty: 'minimumQty',
      properties: ['minimumQty', 'minimumOrderQty', 'orderUnitQty'],
      selectedWarehouses: [],
      inventory: [],
      headers: [],
    }
  },
  watch: {
    selectedWarehouses() {
      this.initMatrix()
    },
    selectedProperty() {
      this.initMatrix()
    },
    $props: {
      handler() {
        this.initMatrix()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.selectedWarehouses = this.getItems('warehouseId').map((warehouse) => warehouse.value)
  },
  methods: {
    getItems(field) {
      return globalStore.getItems(field, this.$store)
    },
    async updateCell(key, value) {
      const skuFound = this.product.SKUS.find((sku) => sku.barcode === key.barcode)
      if (!skuFound.usedByTenant) await this.saveProductToTenantDb(skuFound)

      eventBus.$emit('inventoryManagementUpdate', {
        warehouseId: key.warehouseId,
        barcode: key.barcode,
        property: this.selectedProperty,
        qty: value,
      })
    },
    async saveProductToTenantDb(sku) {
      this.$store.state.loading = true
      await this.$store.dispatch('updateSkuObjects', { data: [sku], audit: 'Add sku to tenant DB for reorder' })
      this.$forceUpdate()
      this.$store.state.loading = false
    },
    barcodeExist(row, header) {
      return this.product.INDEX[productFunctions.indexKey(row.color, row.variant, header.text)]
    },
    warehouseById(warehouseId) {
      return globalStore.getLatestCollectionObject('warehouse')[warehouseId]
    },
    initMatrix() {
      const warehouseById = this.warehouseById
      this.inventory = []

      const product = this.product
      const inventory = this.inventory

      this.headers = [swT('color')].concat(product.SIZES.map((size) => size)).concat([swT('total')])

      const addLinesToInventoryArray = function(color, subColor, indexedInventory, product, inventory) {
        Object.keys(indexedInventory).forEach((warehouseId) => {
          const shopName = warehouseById(warehouseId)?.name
          const inventoryRow = {
            group: color.color + (subColor ? ' ' + subColor : '') + ' - ' + shopName,
            shop: shopName,
            warehouseId: warehouseId,
            colorCodeSupplier: color.colorCodeSupplier,
            cells: [],
          }
          product.SIZES.forEach((size) => {
            const id = productFunctions.indexKey(color.color, subColor, size)
            const qty = indexedInventory[warehouseId][product.INDEX[id]]
            const key = product.INDEX[id] ? { barcode: product.INDEX[id], warehouseId: warehouseId } : undefined
            inventoryRow.cells.push({
              key: key,
              value: qty || 0,
            })
          })

          inventory.push(inventoryRow)
        })
      }
      // procInventory will create an array for the swMatrix component
      const procInventory = function(indexedInventory) {
        product.COLORS.forEach((color) => {
          if (color.subColors?.length > 0) {
            color.subColors.forEach((subColor) => {
              addLinesToInventoryArray(color, subColor, indexedInventory, product, inventory)
            })
            return
          }
          addLinesToInventoryArray(color, '', indexedInventory, product, inventory)
        })
      }
      const selectedWarehouses = deepCopy(this.selectedWarehouses)
      const inventoryManagement = globalStore.getLatestCollectionObject('inventorymanagement')
      const relevantInventory = {}
      product.SKUS.forEach((sku) => {
        const inventoryManagementData = inventoryManagement[sku.barcode]?.data || {}
        selectedWarehouses.forEach((wh) => {
          if (!relevantInventory[wh]) relevantInventory[wh] = {}
          relevantInventory[wh][sku.barcode] = inventoryManagementData[wh] ? inventoryManagementData[wh][this.selectedProperty] : 0
        })
      })
      procInventory(relevantInventory)
    },
  },
}
</script>
