<template>
  <v-container class="ma-0 pa-0">
    <v-row>
      <v-col cols="12" :md="selectedProduct.IMAGES.length > 0 ? 6 : 12">
        <div v-if="selectedProduct.IMAGES.length == 0" align="center" class="mt-4">
          <span class="red--text" style="font-size:48px">[</span>
          <v-icon x-large class="mb-5 mx-5">mdi-image-off</v-icon>
          <span class="red--text" style="font-size:48px">]</span>
        </div>
        <div v-else>
          <v-carousel v-if="showCarousel" height="75vh" hide-delimiters>
            <v-carousel-item
              v-for="(item, i) in selectedProduct.IMAGES"
              :key="i"
              :src="imageUrl(item.url)"
              reverse-transition="fade-transition"
              transition="fade-transition"
            ></v-carousel-item>
          </v-carousel>
          <div v-else>
            <div v-for="(item, i) in selectedProduct.IMAGES" :key="i">
              <v-img :src="imageUrl(item.url)"></v-img>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" :md="selectedProduct.IMAGES.length > 0 ? 6 : 12" class="py-0 px-6">
        <div :class="setSticky">
          <h1>{{ selectedProduct.articleDescription }}</h1>
          <v-select
            v-model="$store.state.b2bSelectedWarehouses"
            multiple
            :disabled="$store.state.b2bBasketCount != 0"
            :items="getWarehouses()"
            hide-details
            dense
            outlined
            class="mb-2"
          ></v-select>
          <swMatrix v-if="matrixReady" :headers="headers" :rows="rows" :show-eye="true" @update="updateCell" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { swT } from '@/functions/i18n'
import globalStore from '@/store/globalStore'
import swMatrix from '../components/swMatrix.vue'
import productFunctions from '../functions/products'
import { eventBus } from '@/functions/eventBus'
import userFunctions from '@/functions/users'

export default {
  components: {
    swMatrix,
  },
  props: ['value', 'product', 'brand', 'brandProducts'],
  data() {
    return {
      swT,
      slideGroupModel: 0,
      valid: false,
      selectedProduct: {},
      rows: [],
      headers: [],
      matrixReady: false,
    }
  },
  computed: {
    showCarousel() {
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)
    },
    setSticky() {
      return this.showCarousel ? '' : 'sticky-3'
    },
  },
  watch: {
    '$store.state.b2bSelectedWarehouses': {
      handler() {
        this.initMatrix()
      },
      immediate: true,
    },
    product: {
      handler() {
        if (this.product) {
          this.reset()
        }
      },
      deep: false,
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch('clearFilter')
    if (this.$store.state.b2bSelectedWarehouses.length == 0) {
      if (this.$store.state.activeConfig.favorites.defaultwarehouse.value) {
        const warehouse = this.warehouseByName(this.$store.state.activeConfig.favorites.defaultwarehouse.value)
        this.$store.state.b2bSelectedWarehouses = [warehouse.code]
      } else this.$store.state.b2bSelectedWarehouses = this.getWarehouses().map((warehouse) => warehouse.value)
    }
    eventBus.$on('updateB2BForm', () => {
      this.initMatrix()
      this.$forceUpdate()
    })
  },

  activated() {
    this.$store.dispatch('clearFilter')
    this.initMatrix()
    this.$vuetify.goTo(0)
  },
  methods: {
    imageUrl(url) {
      if (url.substring(0, 4) == 'http') return url
      return `https://${url}`
    },
    reset() {
      this.selectedProduct = this.product
      this.initMatrix()
      this.$forceUpdate()
      this.$vuetify.goTo(0)
    },
    getWarehouses() {
      // lc-data-only users rely on pricat messages so we limit wareouses to those with a GLN
      if (userFunctions.hasRole(this.$store.getters.roles, 'lc-data-only')) return globalStore.getItems('warehouseIdWithGln', this.$store)

      return globalStore.getItems('warehouseId', this.$store)
    },
    initMatrix() {
      const warehouseById = this.warehouseById
      this.rows = []
      const product = this.product
      const rows = this.rows

      this.headers = [swT('warehouse')].concat(product.SIZES.map((size) => size)).concat([swT('total')])

      const addLinesToMatrix = function(color, subColor, indexedInventory, product, rows) {
        Object.keys(indexedInventory).forEach((warehouseId) => {
          const shopName = warehouseById(warehouseId)?.name
          const inventoryRow = {
            group: color.color + (subColor ? ' ' + subColor : '') + ' - ' + shopName,
            shop: shopName,
            warehouseId: warehouseId,
            colorCodeSupplier: color.colorCodeSupplier,
            cells: [],
          }
          product.SIZES.forEach((size) => {
            const id = productFunctions.indexKey(color.color, subColor, size)
            const qty = indexedInventory[warehouseId][product.INDEX[id]]
            const key = product.INDEX[id] ? { barcode: product.INDEX[id], warehouseId: warehouseId } : undefined
            inventoryRow.cells.push({
              key: key,
              value: qty || 0,
            })
          })

          rows.push(inventoryRow)
        })
      }
      // procOrder will create an array for the swMatrix component
      const procOrder = function(indexedInventory) {
        product.COLORS.forEach((color) => {
          if (color.subColors?.length > 0) {
            color.subColors.forEach((subColor) => {
              addLinesToMatrix(color, subColor, indexedInventory, product, rows)
            })
            return
          }
          addLinesToMatrix(color, '', indexedInventory, product, rows)
        })
      }
      const b2bOrder = {}
      product.SKUS.forEach((sku) => {
        this.$store.state.b2bSelectedWarehouses.forEach((wh) => {
          if (!b2bOrder[wh]) b2bOrder[wh] = {}
          const orderForWarehouse = this.$store.state.b2bBasket[wh]
          if (orderForWarehouse) {
            for (const product of Object.values(orderForWarehouse)) {
              if (product[sku.barcode]) {
                b2bOrder[wh][sku.barcode] = product[sku.barcode]
                return
              }
            }
            return
          }
          b2bOrder[wh][sku.barcode] = 0
        })
      })
      procOrder(b2bOrder)
      this.matrixReady = true
    },
    async updateCell(key, value) {
      value = parseInt(value)

      if (!this.$store.state.b2bDrawer && !this.showCarousel) this.$store.state.b2bDrawer = true
      const barcode = key.barcode
      const warehouseId = key.warehouseId

      const latestCollectionBrandSkus = globalStore.getB2bSkus()
      const sku = latestCollectionBrandSkus[barcode]
      const articleCodeSupplier = sku.articleCodeSupplier

      if (!this.$store.state.b2bBasket[warehouseId]) this.$store.state.b2bBasket[warehouseId] = {}
      if (!this.$store.state.b2bBasket[warehouseId][articleCodeSupplier]) this.$store.state.b2bBasket[warehouseId][articleCodeSupplier] = {}
      this.$store.state.b2bBasket[warehouseId][articleCodeSupplier][barcode] = value

      if (value == 0) delete this.$store.state.b2bBasket[warehouseId][articleCodeSupplier][barcode]
      if (Object.keys(this.$store.state.b2bBasket[warehouseId][articleCodeSupplier]).length == 0) delete this.$store.state.b2bBasket[warehouseId][articleCodeSupplier]
      if (Object.keys(this.$store.state.b2bBasket[warehouseId]).length == 0) delete this.$store.state.b2bBasket[warehouseId]
      this.$store.state.b2bBasketUpdateTrigger++
    },
    warehouseById(warehouseId) {
      return globalStore.getLatestCollectionObject('warehouse')[warehouseId]
    },
    warehouseByName(warehouseName) {
      return globalStore.getLatestCollectionArray('warehouse').find((wh) => wh.name == warehouseName)
    },
  },
}
</script>
